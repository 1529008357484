import { service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { Ability } from 'ember-can';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import { GLOBAL_ADMIN } from 'garaje/utils/roles';
import _intersection from 'lodash/intersection';

const ALLOWED_ROLES = [GLOBAL_ADMIN];

export default class TeamsUpgrade extends Ability {
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;

  get canSeeUpgradeBanner(): boolean {
    // Turn off banner if the banner split is off
    if (!this.featureFlags.isEnabled('ms-teams-v2-upgrade-banner')) {
      return false;
    }

    const { roleNames } = this.currentAdmin;
    return isPresent(_intersection(ALLOWED_ROLES, roleNames));
  }
}
