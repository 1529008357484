import { service } from '@ember/service';
import EmberRouter from '@embroider/router';
import config from 'garaje/config/environment';
import type PerformanceLoadTime from 'garaje/services/performance-load-time';
import routerPageTitle from 'garaje/utils/decorators/router-page-title';

const shouldMountStyleGuide = !(config.environment === 'production' && config.branch === 'master');

@routerPageTitle
class Router extends EmberRouter {
  location = config.locationType;
  rootURL = config.rootURL;

  @service declare performanceLoadTime: PerformanceLoadTime;

  constructor(...args: unknown[]) {
    // @ts-ignore
    super(...args);
    this.on('routeDidChange', this.handlePerformanceRouteChange.bind(this));
  }

  // Prevent page transitions after initial load from appearing in metrics
  handlePerformanceRouteChange(): void {
    if (!window.appMeasurementTaken && !this.performanceLoadTime.isRouteSupported()) {
      window.appMeasurementTaken = true;
    }
  }
}

// these routes on top of can exist in both a property and location context
export const SHARED_ROUTES = [
  'apps',
  'connect',
  'loading',
  'location-overview',
  'new-location',
  'profile',
  'unauthorized',
];

// these routes are only available in a location context
export const LOCATION_ROUTES = [
  'deliveries',
  'desks',
  'employees',
  'location-settings',
  'rooms',
  'spaces',
  'visitors',
  'workplace',
];

Router.map(function () {
  if (shouldMountStyleGuide) {
    this.route('style-guide');
  }
  this.route('login');
  this.route('ipad-demo');
  this.route('pre-registered');
  this.route('auth');
  this.route('auth-redirect');
  this.route('protected', { path: '/' }, function () {
    this.route('current-zone', { path: '/', resetNamespace: true }, function () {
      this.route('current-location', { path: '/', resetNamespace: true }, function () {
        this.route('workplace', { resetNamespace: true }, function () {
          this.route('trial-ended');
          this.route('start-trial');
          this.route('setup-guide', function () {});
          this.route('entries', function () {});
          this.route('entry', { path: 'entries/:entry_id' });
          this.route('scheduled', { path: 'scheduled/:invite_id' });
          this.route('access-events', function () {});
          this.route('access-event', { path: 'access-events/:access_event_id' });
          this.route('announcements', function () {
            this.route('landing-page');
            this.route('list', { path: '/' });
            this.route('show', { path: ':announcement_id' });
          });
          this.route('settings', function () {
            this.route('employees', function () {
              this.route('registration', function () {
                this.route('edit', { path: ':flow_id' });
              });
            });
          });
        });
        this.route('dashboard', { path: '/', resetNamespace: true }, function () {
          this.route('welcome');
        });
        this.route('deliveries', { resetNamespace: true }, function () {
          this.route('get-started');
          this.route('landing-page');
          this.route('log', function () {
            this.route('delivery', { path: ':id' }, function () {
              this.route('photo');
              this.route('sign-out-photo');
            });
            this.route('tutorial');
          });

          this.route('settings', function () {
            this.route('notifications');
            this.route('nicknames');

            this.route('delivery-areas', function () {
              this.route('new');
              this.route('delivery-area', { path: ':delivery_area_id' }, function () {
                this.route('analytics'); // legacy only need to keep for redirect
                this.route('notifications');
                this.route('devices', function () {
                  this.route('new');
                });
                this.route('pick-up');
              });
            });
          });
        });
        this.route('billing', { resetNamespace: true }, function () {
          this.route('change-plan', { path: 'change-plan/:plan' });
          this.route('confirm-downgrade', { path: 'confirm-downgrade/:plan' });
          this.route('downgrade', { path: 'downgrade/:plan' });
          this.route('index', { path: '/' }, function () {
            this.route('add-quantity', { path: ':product/add' });
            this.route('billing-period', { path: ':product/billing-period' });
            this.route('confirm-purchase', { path: 'confirm-purchase/:product' });
          });
          this.route('product-plans', { path: ':product/plans' }, function () {
            this.route('index', { path: '/' }, function () {
              this.route('downgrade', { path: 'downgrade/:plan' });
            });
            this.route('upgrade', { path: 'upgrade/:plan' });
          });
          this.route('plans');
          this.route('subscribe');
        });
        this.route('changelogs', { resetNamespace: true }, function () {});
        this.route('employees', { resetNamespace: true }, function () {
          this.route('directory', { path: '/' }, function () {
            this.route('add-employee', function () {
              this.route('from-location');
              this.route('new');
            });
            this.route('employee', { path: ':employee_id' });
          });
          this.route('document-review', function () {
            this.route('single-document', { path: '/:id' });
          });
          this.route('import');
          this.route('sync-settings');
          this.route('scim-settings');
          this.route('integrations');
        });
        this.route('integrations', { resetNamespace: true }, function () {
          this.route('enabled');
        });
        this.route('apps', { resetNamespace: true }, function () {
          this.route('dynamic', { path: '/*path' });
          this.route('settings');
        });
        this.route('dev-dashboard', { resetNamespace: true });

        // Delete once we finish the migration of old links in the help-center
        this.route('invites', { resetNamespace: true });
        this.route('invites', { path: '/invites/*path', resetNamespace: true });
        this.route('entries', { resetNamespace: true });
        this.route('entries', { path: '/entries/*path', resetNamespace: true });
        this.route('settings', { resetNamespace: true });
        this.route('settings', { path: '/settings/*path', resetNamespace: true });

        this.route('property', { resetNamespace: true }, function () {
          this.route('visitors', { path: '/' }, function () {
            this.route('blocklist', function () {
              this.route('import');
            });
            this.route('invites', function () {
              this.route('edit-checkin', { path: 'edit-checkin/:connect_invite_id' });
              this.route('edit', { path: 'edit/:connect_invite_id' });
              this.route('new');
            });
          });
          this.route('directory', function () {});
          this.route('devices', function () {
            this.route('ipads', function () {
              this.route('new');
            });
            this.route('printers', function () {
              this.route('connections', function () {
                this.route('new');
                this.route('show', { path: ':id' });
              });
              this.route('new');
            });
          });
          this.route('apps');
          this.route('settings', function () {
            this.route('edit', { path: '/' });
            this.route('floors', function () {
              this.route('new');
              this.route('edit', { path: '/:id' });
            });
            this.route('suites');
            this.route('tenants', function () {
              this.route('permissions', { path: 'permissions/:tenant_id' });
              this.route('list', { path: '/' }, function () {
                this.route('edit', { path: ':tenant_id' });
                this.route('new');
              });
            });
            this.route('badges');
            this.route('kiosk', function () {
              this.route('welcome-screen');
              this.route('final-screen');
              this.route('assistance-screen');
            });
            this.route('security');
          });
        });

        this.route('location-overview', { resetNamespace: true }, function () {
          this.route('global-devices', function () {
            this.route('ipads');
            this.route('printers');
          });
          this.route('locations', function () {
            this.route('groups', function () {
              this.route('new');
            });
          });
          this.route('occupancy', function () {
            this.route('analytics', function () {});
            this.route('settings', function () {});
          });
          this.route('visitor-log');
          this.route('sign-in-flows', function () {
            this.route('show', { path: ':global_flow_id' }, function () {
              this.route('sign-in-fields');
              this.route('legal-documents', function () {
                this.route('edit', { path: '/:agreement_id' });
                this.route('new');
              });
              this.route('badge');
              this.route('visitor-photos');
              this.route('plus-one');
              this.route('security');
              this.route('final-screen');
            });
          });
          this.route('analytics', function () {
            this.route('dashboard', { path: '/' });
            this.route('attendance', function () {
              this.route('overview');
              this.route('report');
            });
            this.route('reports');
            this.route('upsell', { path: 'upgrade' });
            this.route('occupancy', function () {});
            this.route('visitors', function () {});
            this.route('protect', function () {
              this.route('upsell', { path: 'upgrade' });
            });
            this.route('desks', function () {});
            this.route('deliveries', function () {});
            this.route('rooms', function () {});
            this.route('benchmarking', function () {});
            this.route('space-index', function () {});
          });
          this.route('communications', function () {
            this.route('message-log', function () {
              this.route('list', { path: '/' });
              this.route('show', { path: ':announcement_id' });
              this.route('new');
            });
            this.route('templates', function () {
              this.route('get-started');
              this.route('list', { path: '/' });
              this.route('edit', { path: ':template_id' });
              this.route('new');
            });
            this.route('settings');
          });
          this.route('employees', function () {
            this.route('directory', { path: '/' }, function () {
              this.route('add-employee', function () {
                this.route('new');
              });
              this.route('employee', { path: ':employee_id' });
            });
            this.route('sync-settings');
            this.route('scim-settings');
            this.route('integrations');
          });
          this.route('manage', function () {
            this.route('admin-users', function () {
              this.route('edit', { path: ':user_id/edit' });
              this.route('bulk');
              this.route('single-delete', { path: ':user_id/delete' });
            });
            this.route('roles');
            this.route('view-role', { path: 'roles/:role_id/view' });
            this.route('create-role', { path: 'roles/:role_id/create' });
            this.route('edit-role', { path: 'roles/:role_id/edit' });
            this.route('account-settings');
            this.route('audit-log');
          });
          this.route('virtual-front-desk', function () {
            this.route('landing-page');
            this.route('call-log');
            this.route('locations', function () {
              this.route('settings', { path: '/:id' });
            });
            this.route('devices', function () {
              this.route('ipads');
            });
            this.route('settings');
          });
        });
        this.route('my-locations', { resetNamespace: true }, function () {
          this.route('visitor-log');
        });
        this.route('meeting', { path: 'meetings/:id', resetNamespace: true });
        this.route('new-location', { path: 'settings/new-location', resetNamespace: true });
        this.route('profile', { resetNamespace: true }, function () {
          this.route('notifications');
          this.route('documents');
          this.route('document', { path: 'document/:document' }, function () {
            this.route('detail');
            this.route('upload', function () {
              this.route('attachment', { path: ':attachment' });
              this.route('review');
              this.route('confirm');
            });
          });
          this.route('onboarding');
        });

        /** Legacy rooms that was replaced by roomba. Now serves only as a redirect to roomba */
        this.route('roomba-redirect', { path: 'roomba', resetNamespace: true }, function () {});

        /** Roomba (the 'new' rooms) */
        this.route('roomba', { path: 'rooms', resetNamespace: true }, function () {
          this.route('landing-page');
          this.route('list');
          this.route('room', { path: ':room_id' });
          this.route('settings', function () {
            this.route('calendars');
            this.route('permissions');
          });
        });

        this.route('desks', { resetNamespace: true }, function () {
          this.route('show', { path: ':floor_id' });
          this.route('reservations');
          this.route('import');
          this.route('get-started');
          this.route('landing-page');
          this.route('settings');
        });

        this.route('spaces', { resetNamespace: true }, function () {
          this.route('maps', function () {
            this.route('neighborhoods');
            this.route('drafts', function () {
              this.route('area-map', { path: ':area_map_id' }, function () {
                this.route('show', { path: '/floor/:floor_id' });
              });
            });
            this.route('live', function () {
              this.route('show', { path: ':floor_id' });
            });
            this.route('edit', function () {
              this.route('show', { path: ':floor_id' });
            });
            this.route('versions', function () {
              this.route('show', { path: ':map_version_id' });
            });
          });
        });

        this.route('maps', { resetNamespace: true }, function () {
          this.route('live', function () {
            this.route('show', { path: ':floor_id' });
          });
          this.route('edit', function () {
            this.route('show', { path: ':floor_id' });
          });
        });

        this.route('communications', { resetNamespace: true }, function () {
          this.route('message-log', function () {
            this.route('list', { path: '/' });
            this.route('show', { path: ':announcement_id' });
            this.route('new');
          });
          this.route('templates', function () {
            this.route('get-started');
            this.route('list', { path: '/' });
            this.route('edit', { path: ':template_id' });
            this.route('new');
          });
          this.route('settings');
        });

        this.route('protect', { resetNamespace: true }, function () {
          this.route('landing-page');
          // not handled - should go to employees/registration
          this.route('settings', function () {
            // redirects to employees/registration
            this.route('employees', function () {
              this.route('registration', function () {
                this.route('edit', { path: ':flow_id' });
              });
            });
            this.route('capacity');
          });
          this.route('announcements', function () {
            this.route('list', { path: '/' });
            this.route('show', { path: ':announcement_id' });
            this.route('landing-page');
          });
        });

        this.route('security', { resetNamespace: true }, function () {
          this.route('index', { path: '/' });
          this.route('blocklist-import');
        });

        this.route('security-desk', { resetNamespace: true });

        this.route('visitors', { resetNamespace: true }, function () {
          this.route('trial-ended', function () {});
          this.route('approvals', function () {});
          this.route('entries', function () {});
          this.route('entry', { path: 'entries/:entry_id' });
          this.route('setup-guide', function () {});
          this.route('invites', function () {
            this.route('new');
            this.route('show', { path: ':invite_id' });
            this.route('groups', function () {
              this.route('show', { path: ':group_invite_id' });
            });
            this.route('deleted');
          });
          this.route('start-trial');
          this.route('new');
          this.route('devices', function () {
            this.route('ipads', function () {
              this.route('new');
            });
            this.route('printers', function () {
              this.route('new');
              this.route('connections', function () {
                this.route('new');
                this.route('show', { path: ':printer_connection_id' });
              });
            });
            this.route('alerts');
          });

          this.route('settings', function () {
            this.route('visitor-entrances', function () {
              this.route('index', { path: '/' });
              this.route('visitor-entrance', { path: ':visitor_entrance_id' });
            });

            this.route('integrations', function () {
              this.route('enabled');
            });
            this.route('location', { path: 'account' });
            this.route('notifications');
            this.route('pre-registration');
            this.route('invites');
            this.route('pre-registration-upgrade');
            this.route('visitor-emails');
            this.route('emails', function () {
              this.route('templates', { path: '/:mailer_template_id' }, function () {
                this.route('index', { path: '/' });
                this.route('versions', { path: '/versions/:template_version_id' });
              });
            });
            // "V2" Mailer Templates
            this.route('email-templates', function () {
              this.route('show', { path: '/:envoy_default_id' }, function () {
                this.route('custom', { path: '/:mailer_template_id' });
              });
            });
            this.route('sign-out');
            this.route('welcome-screen', function () {
              this.route('returning-visitors');
              this.route('deliveries');
              this.route('design');
              this.route('multiple-languages');
              this.route('touchless-walkins');
              this.route('host-search');
            });
            this.route('visitor-types', function () {
              this.route('new');
              this.route('default');
              this.route('flow', { path: ':flow_id' }, function () {
                this.route('sign-in-fields');
                this.route('legal-documents', function () {
                  this.route('edit', { path: '/:agreement_id' });
                  this.route('new');
                });
                this.route('badge');
                this.route('visitor-photos');
                this.route('plus-one');
                this.route('security');
                this.route('final-screen');
                this.route('visitor-emails');
              });
            });
          });
        });

        this.route('virtual-front-desk', { resetNamespace: true }, function () {
          this.route('landing-page');
          this.route('devices', function () {
            this.route('ipads', function () {
              this.route('new');
            });
            this.route('alerts');
          });
          this.route('settings', function () {
            this.route('call-operations');
            this.route('welcome-screen', function () {
              this.route('design');
              this.route('multiple-languages');
            });
          });
        });

        this.route('manage', { resetNamespace: true }, function () {
          this.route('admin-users', function () {
            this.route('edit', { path: ':user_id/edit' });
            this.route('bulk');
            this.route('single-delete', { path: ':user_id/delete' });
          });
          this.route('roles');
          this.route('view-role', { path: 'roles/:role_id/view' });
          this.route('create-role', { path: 'roles/:role_id/create' });
          this.route('edit-role', { path: 'roles/:role_id/edit' });
          this.route('location-settings', function () {
            this.route('connect-permissions', { path: 'connect-permissions/:tenant_id' });
            this.route('index', { path: '/' }, function () {
              this.route('connect');
            });
          });
          this.route('audit-log');
        });

        this.route('connect', { resetNamespace: true }, function () {
          this.route('properties', function () {
            this.route('new');
          });
          this.route('analytics', function () {
            this.route('occupancy');
          });
        });

        this.route('schedule', { resetNamespace: true });

        /** Analytics */
        this.route('analytics', { resetNamespace: true }, function () {
          this.route('occupancy', function () {});
          this.route('visitors', function () {});
          this.route('protect', function () {
            this.route('upsell', { path: 'upgrade' });
          });
          this.route('deliveries', function () {});
          this.route('desks', function () {});
          this.route('rooms', function () {});
          this.route('reports', function () {});
          this.route('attendance', function () {
            this.route('overview');
            this.route('report');
          });
          this.route('strategic-insights', function () {});
        });

        /* Location Settings */
        this.route('location-settings', { resetNamespace: true }, function () {
          this.route('connect-permissions', { path: 'connect-permissions/:tenant_id' });
          this.route('index', { path: '/' }, function () {
            this.route('connect');
          });
        });

        // these "standalone" routes actually need to be nested under current-location route
        // because they contain a "Go to Dashboard" button that relies on the can visit
        // dashboard ability. That ability requires currentAdmin to be resolved, which is
        // only done in current-location now.
        this.route('unauthorized', { resetNamespace: true });
        this.route('forbidden', { resetNamespace: true });
        // This route needs to come last
        this.route('not-found', { path: '/*path', resetNamespace: true });

        // Location Billing Route Top - all below WIP
        this.route('location-billing', { resetNamespace: true }, function () {
          // Index is home view
          // includes Payment component and billing history components
          this.route('subscriptions', function () {
            this.route('summary'); // CART - Purchase Summary - Before Changes are Requested / Made
            // Final GO screen or button
            this.route('status'); // Post changes page
            // Not part of above flow
            this.route('change-period'); // Component in Summary and optionally in subscription index but hidden behind button
          });
        });
      });
    });
  });
});

export default Router;
