import { A } from '@ember/array';
import { action, set, setProperties, get } from '@ember/object';
import { service } from '@ember/service';
import { isPresent, isEmpty } from '@ember/utils';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { Task } from 'ember-concurrency';
import { timeout, dropTask, restartableTask } from 'ember-concurrency';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports
import type DS from 'ember-data';
import { singularize } from 'ember-inflector';
import type EmployeeModel from 'garaje/models/employee';
import { type ScimCategory } from 'garaje/models/neighborhood';
import type NeighborhoodModel from 'garaje/models/neighborhood';
import type AjaxService from 'garaje/services/ajax';
import type CohoService from 'garaje/services/coho';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import type { EmployeeSearcherTask } from 'garaje/utils/employees-searcher';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { APP, NEIGHBORHOOD_SCIM_CATEGORIES, WorkplaceEventNames } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import { defer } from 'rsvp';
// eslint-disable-next-line ember/use-ember-data-rfc-395-imports

/**
 * @param {Class<NeighborhoodModel>}             neighborhood    Required. Neighborhood model
 * @param {Class<DirectorySyncProvider>}    directorySyncProvider
 */

interface NeighborhoodBoxArgs {
  neighborhood: NeighborhoodModel;
  directorySyncProvider: DirectorySyncProvider | undefined;
  neighborhoods: DS.RecordArray<NeighborhoodModel>;
}

type DirectorySyncProvider = {
  name: string | undefined;
  provider: string | undefined;
};

type ResponseData = {
  data: ScimData[];
};

type Group = {
  id: number;
  name: string;
};

type ScimData = {
  id: string;
  type: string;
  attributes: {
    departments: string[];
    divisions: string[];
    organizations: string[];
    groups: Group[];
  };
};

type ScimCategoryWithGroupName = {
  groupName: string;
  options: ScimCategory[];
};

type ScimCategoryWithSelectedLabel = ScimCategory & { selectedLabel: string };

const DEBOUNCE_MS = 250;
export default class NeighborhoodBox extends Component<NeighborhoodBoxArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare ajax: AjaxService;
  @service declare store: Store;
  @service declare state: StateService;
  @service declare metrics: MetricsService;
  @service declare coho: CohoService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare statsig: StatsigService;

  @tracked isEditing = false;
  @tracked newNeighborhoodName = this.args.neighborhood.name; // eslint-disable-line ember/no-tracked-properties-from-args
  @tracked neighborhoodNameError: string[] = [];

  constructor(owner: unknown, args: NeighborhoodBoxArgs) {
    super(owner, args);
    void (this.args.neighborhood.loadEmployees as unknown as Task<void, []>).perform();
  }

  @(employeesSearcherTask({
    filter: { deleted: false },
    prefix: true,
  }).restartable())
  searchEmployeesTask!: EmployeeSearcherTask;

  get sittingHereText(): string {
    const { employees } = this.args.neighborhood;
    if (isEmpty(employees) && isEmpty(this.selectedCategories)) {
      return 'Anyone can sit here';
    }
    const sitting = [];
    if (isPresent(this.selectedCategories)) {
      sitting.push(...this.selectedCategories.map(({ selectedLabel }) => selectedLabel));
    }
    if (isPresent(employees)) {
      sitting.push(...employees.map(({ name }) => name));
    }
    return 'Only ' + sitting.join(', ').replace(/, ((?:.(?!, ))+)$/, ' and $1') + ' can sit here';
  }

  get providerName(): string | undefined {
    const provider = this.args.directorySyncProvider;
    if (!provider) {
      return provider;
    } else {
      return provider.name || provider.provider;
    }
  }

  get selectedCategories(): ScimCategoryWithSelectedLabel[] {
    const { scimCategories } = this.args.neighborhood;
    const categories = scimCategories.map((category) => {
      const selectedLabel = `${category.name} (${this.providerName} ${singularize(
        category.categoryName,
      ).toLowerCase()})`;
      return { ...category, selectedLabel };
    });
    return categories;
  }

  cleanExtraFilters(extraFilters: unknown): Record<string, unknown> {
    const allowedFilters = ['except', 'locations']; // Add any other filter when needed
    const cleaned: Record<string, unknown> = {};
    allowedFilters.forEach((filterName) => {
      if (get(extraFilters, filterName)) {
        cleaned[filterName] = get(extraFilters, filterName);
      }
    });
    return cleaned;
  }

  @dropTask
  *saveTask(): Generator<Promise<NeighborhoodModel>, void, void> {
    const { neighborhood } = this.args;
    try {
      set(neighborhood, 'name', this.newNeighborhoodName);
      yield neighborhood.save();

      const data = {
        data: (neighborhood?.captains?.toArray() || []).map(({ id }) => ({
          type: 'employees',
          id,
        })),
      };

      const url = urlBuilder.rms.updateCaptainsUrl(neighborhood.id);

      yield this.ajax.request(url, {
        contentType: 'application/json',
        method: 'POST',
        data,
      });

      this.isEditing = false;
      this.metrics.trackEvent('Updated neighborhood', {
        neighborhoodId: neighborhood.id,
        neighborhoodName: neighborhood.name,
        neighborhoodCaptainCount: neighborhood?.captains?.length || 0,
      });
      this.flashMessages.showAndHideFlash('success', 'Neighborhood saved!');
      this.coho.sendEvent(WorkplaceEventNames.NEIGHBORHOOD_EDITED, { product: APP.WORKPLACE });
      this.statsig.logEvent(`coho_${WorkplaceEventNames.NEIGHBORHOOD_EDITED}`, null, {
        product: APP.WORKPLACE,
        location_id: this.state?.currentLocation?.id,
      });
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log({ e });
      const message = 'Server error. Please try again.';
      this.flashMessages.showAndHideFlash('error', message);
    }
  }

  @dropTask
  showDeleteNeighborhoodConfirmationModalTask = {
    *perform(this: {
      context: NeighborhoodBox;
      abort?: () => void;
      continue?: () => Promise<void>;
    }): Generator<Promise<boolean>, void, void> {
      const deferred = defer<boolean>();
      this.abort = () => deferred.resolve(false);
      this.continue = async () => {
        const { neighborhood } = this.context.args;
        try {
          this.context.metrics.trackEvent('Deleted neighborhood', {
            neighborhoodId: neighborhood.id,
            neighborhoodCaptainCount: neighborhood.captains?.length || 0,
            neighborhoodName: neighborhood.name,
          });
          await neighborhood.destroyRecord();
          this.context.flashMessages.showAndHideFlash('success', 'Neighborhood deleted!');
        } catch (e) {
          this.context.flashMessages.showAndHideFlash('error', 'Error', parseErrorForDisplay(e));
        }
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @restartableTask
  *searchScimTask(term: string): Generator<unknown, unknown[], ResponseData> {
    if (!term) {
      return [];
    }
    const limit = 50;
    yield timeout(zft(DEBOUNCE_MS));
    const url = urlBuilder.v3.scimDataAutocomplete(term, limit);
    try {
      const { data } = yield this.ajax.request<ResponseData>(url);
      if (!data || data.length == 0 || data[0] == undefined) {
        return [];
      }
      return this._buildCategories(data[0]);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error({ e });
      return [];
    }
  }

  @action
  setNeighborhoodName(name: string): void {
    const neighborhood: NeighborhoodModel = this.args.neighborhood;
    this.validateNeighborhoodName(neighborhood, name);
    this.newNeighborhoodName = name;
  }

  validateNeighborhoodName(selectedNeighborhood: NeighborhoodModel, name: string): void {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const neighborhoods: DS.RecordArray<NeighborhoodModel> = this.args.neighborhoods;
    if (name === '' || name === null) {
      this.neighborhoodNameError = ['Please enter a neighborhood name'];
    } else if (neighborhoods.any((hood) => hood.name === name && hood !== selectedNeighborhood && !hood.isDeleted)) {
      this.neighborhoodNameError = ['Neighborhood name already exists'];
    } else {
      this.neighborhoodNameError = [];
    }
  }

  @action
  onEmployeeChange(employees: EmployeeModel[]): void {
    const { neighborhood } = this.args;
    const employeeIds = employees.map(({ id }) => id);
    set(neighborhood, 'employeeIds', employeeIds);
    // Refocus in the input
    (
      document.querySelector(
        '[data-focus="employee-selector"] .ember-power-select-trigger-multiple-input',
      ) as HTMLElement
    ).focus();
  }

  @action
  onCaptainChange(employees: EmployeeModel[]): void {
    const { neighborhood } = this.args;
    neighborhood.captains.setObjects(A(employees));

    this.metrics.trackEvent('Modified unsaved neighborhood captains', {
      neighborhoodId: neighborhood.id,
      neighborhoodName: neighborhood.name,
      neighborhoodCaptainCount: neighborhood.captains?.length || 0,
    });
  }

  @action
  onScimChange(selected: ScimCategory[]): void {
    const departments: Set<string> = new Set();
    const divisions: Set<string> = new Set();
    const organizations: Set<string> = new Set();
    const scimGroupIds: Set<string> = new Set();

    selected.forEach((option) => {
      const { name, categoryName, id } = option;
      switch (categoryName) {
        case NEIGHBORHOOD_SCIM_CATEGORIES.DEPARTMENTS:
          departments.add(name);
          break;
        case NEIGHBORHOOD_SCIM_CATEGORIES.DIVISIONS:
          divisions.add(name);
          break;
        case NEIGHBORHOOD_SCIM_CATEGORIES.ORGANIZATIONS:
          organizations.add(name);
          break;
        case NEIGHBORHOOD_SCIM_CATEGORIES.GROUPS:
          if (id) {
            scimGroupIds.add(id);
            this.store.push({
              data: { id: parseInt(id), type: 'scim-group', attributes: { displayName: name } },
            });
          }
          break;
      }
    });

    const { neighborhood } = this.args;

    setProperties(neighborhood, {
      scimDivisions: [...divisions],
      scimOrganizations: [...organizations],
      scimDepartments: [...departments],
      scimGroupIds: [...scimGroupIds],
    });
    // Refocus in the input
    (
      document.querySelector('[data-focus="group-selector"] .ember-power-select-trigger-multiple-input') as HTMLElement
    ).focus();
  }

  /**
   * Builds the group (category) format expected by Ember-power-select
   * https://ember-power-select.com/docs/groups
   * @param {Object} scimData array to search
   *
   * @returns {Array}
   */
  _buildCategories(scimData: ScimData): ScimCategoryWithGroupName[] {
    const {
      attributes: { departments, divisions, organizations, groups },
    } = scimData;
    const categories = [];
    if (isPresent(departments)) {
      categories.push(this._pushCategory(departments, NEIGHBORHOOD_SCIM_CATEGORIES.DEPARTMENTS));
    }
    if (isPresent(divisions)) {
      categories.push(this._pushCategory(divisions, NEIGHBORHOOD_SCIM_CATEGORIES.DIVISIONS));
    }
    if (isPresent(organizations)) {
      categories.push(this._pushCategory(organizations, NEIGHBORHOOD_SCIM_CATEGORIES.ORGANIZATIONS));
    }
    if (isPresent(groups)) {
      categories.push(this._pushCategory(groups, NEIGHBORHOOD_SCIM_CATEGORIES.GROUPS));
    }
    return categories;
  }
  _pushCategory(list: string[] | Group[], categoryName: string): ScimCategoryWithGroupName {
    return {
      groupName: `${this.providerName} ${categoryName}`,
      options: list.map((option) => {
        if (typeof option === 'string') {
          return {
            categoryName,
            name: option,
          };
        } else {
          return {
            id: option.id.toString(),
            categoryName,
            name: option.name,
          };
        }
      }),
    };
  }
}
