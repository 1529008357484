import { service } from '@ember/service';
import type { AsyncBelongsTo, AsyncHasMany } from '@ember-data/model';
import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { dropTask } from 'ember-concurrency';
import type AnnouncementAudienceModel from 'garaje/models/announcement-audience';
import type AnnouncementChannelModel from 'garaje/models/announcement-channel';
import type AnnouncementRecipientModel from 'garaje/models/announcement-recipient';
import type CompanyModel from 'garaje/models/company';
import type EmergencyNotificationModel from 'garaje/models/emergency-notification';
import type EmployeeModel from 'garaje/models/employee';
import type EmployeeGroupModel from 'garaje/models/employee-group';
import type LocationModel from 'garaje/models/location';
import type AjaxService from 'garaje/services/ajax';
import type FlashMessagesService from 'garaje/services/flash-messages';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import urlBuilder from 'garaje/utils/url-builder';

export interface AnnouncementStatistics {
  employee_recipients: number;
  visitor_recipients: number;
  sent_employee_recipients: number;
  sent_visitor_recipients: number;
  marked_as_safe_recipients: number;
}

export default class AnnouncementModel extends Model {
  @service declare flashMessages: FlashMessagesService;
  @service declare ajax: AjaxService;

  // Relationships for both old and new
  @belongsTo('company', { async: true, inverse: null }) declare company: AsyncBelongsTo<CompanyModel>;
  @belongsTo('location', { async: true }) declare location: AsyncBelongsTo<LocationModel>;

  //Relationships for new endpoint
  @belongsTo('employee', { async: true }) declare creatorEmployee: AsyncBelongsTo<EmployeeModel>;
  @belongsTo('emergency-notification', { async: true })
  declare emergencyNotification: AsyncBelongsTo<EmergencyNotificationModel>;
  @hasMany('announcement-recipient', { async: true })
  declare announcementRecipients: AsyncHasMany<AnnouncementRecipientModel>;
  @hasMany('announcement-channel', { async: true })
  declare announcementChannels: AsyncHasMany<AnnouncementChannelModel>;
  @hasMany('announcement-audience', { async: true })
  declare announcementAudiences: AsyncHasMany<AnnouncementAudienceModel>;
  @hasMany('employee-group', { async: true }) declare employeeGroups: AsyncHasMany<EmployeeGroupModel>;

  // Common attributes between old and new api
  @attr('string') declare title: string;
  @attr('string') declare description: string;
  @attr('string') declare link: string;
  @attr('boolean') declare shouldSendPushNotification: boolean;
  @attr('date') declare createdAt: Date;

  // Attributes for old api only remove after sunset
  @attr('number') declare numSeen: number;
  @attr('boolean') declare isArchived: boolean;

  // Attributes for new api end point /v3
  @attr('string') declare message: string;
  @attr('boolean') declare isEmergencyNotification: boolean;
  @attr('date') declare archivedAt: Date;
  @attr('boolean') declare archived: boolean;
  @attr('object') declare statistics: AnnouncementStatistics;

  sendPushNotificationTask = dropTask(async () => {
    const url = urlBuilder.rms.sendAnnouncementNotification(this.id);
    try {
      await this.ajax.request(url, {
        // @ts-ignore accept is not a valid attribute for request options
        accept: 'application/vnd.api+json',
        type: 'POST',
      });
      this.flashMessages.showAndHideFlash('success', 'Message sent!');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showFlash('error', errorText);
    }
  });
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    announcement: AnnouncementModel;
  }
}
