import Model, { attr, belongsTo, type AsyncBelongsTo } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
import type AreaMapModel from 'garaje/models/area-map';
import type MapFloorModel from 'garaje/models/map-floor';
import adapter from 'garaje/utils/decorators/adapter';
import { TrackedObject } from 'tracked-built-ins';

import type DeskModel from './desk';

interface MapFeaturePoint {
  type: string;
  coordinates: [number, number];
}

@adapter('maps')
class MapFeatureModel extends Model {
  @attr('string') declare name: string;
  @attr('string') declare notes: string;
  @attr('boolean', { defaultValue: true }) declare enabled: boolean;
  @attr('string') declare type: string;
  @attr('tracked-object', { defaultValue: () => new TrackedObject({ type: 'Point', coordinates: [0, 0] }) })
  declare geometry: MapFeaturePoint;
  @attr('string') declare externalId: string;
  @attr('number') declare parentFeatureId: number;

  @belongsTo('map-floor', { async: true }) declare mapFloor: AsyncBelongsTo<MapFloorModel>;
  @belongsTo('area-map', { async: true }) declare areaMap: AsyncBelongsTo<AreaMapModel>;

  @tracked hasError = false;
  tempId = null;
  desk: DeskModel | null = null;

  get requiredFields(): string[] {
    let fields: string[] = [];

    switch (this.type) {
      case 'visitor-area':
        fields = ['name'];
        break;
      case 'delivery-area':
        fields = ['name', 'externalId'];
        break;
      case 'desk':
        fields = ['name'];
        break;
      case 'room':
        fields = ['name', 'externalId'];
        break;
      default:
        fields = ['name'];
    }

    return fields;
  }
}

export default MapFeatureModel;

declare module 'ember-data/types/registries/model' {
  export default interface ModelRegistry {
    'map-feature': MapFeatureModel;
  }
}
