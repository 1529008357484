import Component from '@glimmer/component';

type FillColorString =
  | 'blue'
  | 'blueLighter'
  | 'blueLightest'
  | 'brand'
  | 'grayDark'
  | 'grayDarker'
  | 'grayLighter'
  | 'greenLighter'
  | 'greenLightest'
  | 'newLightBlue'
  | 'orangeLighter'
  | 'pistachio'
  | 'redLighter';

type SizeString = 'medium';

interface UiPillComponentSignature {
  Args: {
    fill?: FillColorString;
    size?: SizeString;
  };
  Blocks: {
    default: [];
  };
}

export default class UiPillComponent extends Component<UiPillComponentSignature> {
  get colorClassNames(): string {
    switch (this.args.fill) {
      case 'blue':
        return 'text-white bg-gem-50';
      case 'blueLighter':
        return 'text-white bg-gem-20';
      case 'blueLightest':
        return 'text-gem-50 bg-gem-5';
      case 'newLightBlue':
        return 'text-gem-60 bg-gem-10';
      case 'grayDark':
        return 'text-white bg-carbon-50';
      case 'grayDarker':
        return 'text-white bg-carbon-70';
      case 'grayLighter':
        return 'text-carbon-60 bg-carbon-10';
      case 'redLighter':
        return 'text-red-70 bg-red-40';
      case 'orangeLighter':
        return 'text-papaya-60 bg-papaya-20';
      case 'greenLighter':
        return 'text-cilantro-60 bg-cilantro-30';
      case 'greenLightest':
        return 'text-cilantro-60 bg-cilantro-10';
      case 'pistachio':
        return 'text-pistachio-60 bg-pistachio-10';
      case 'brand':
        return 'text-white bg-red-50';
      default:
        return 'border';
    }
  }

  get sizeClasses(): string {
    switch (this.args.size) {
      case 'medium':
        return 'text-xss ml-2';
      default:
        return '';
    }
  }

  get sizeStyles(): string {
    switch (this.args.size) {
      case 'medium':
        return 'font-weight: 700; padding-top: 3px; padding-bottom: 3px;';
      default:
        return '';
    }
  }
}
