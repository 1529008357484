import type VfdContactMethod from 'garaje/models/vfd-contact-method';
import ApplicationSerializer from 'garaje/serializers/application';
import type { SinglePayload } from 'jsonapi/response';

export default class VfdContactMethodSerializer extends ApplicationSerializer {
  serialize(...args: Parameters<ApplicationSerializer['serialize']>): SinglePayload<VfdContactMethod> {
    const payload = <SinglePayload<VfdContactMethod>>super.serialize(...args);

    if ('user' in payload.data.relationships) {
      const userData = payload.data.relationships['user'] as {
        data: {
          id: string;
          type: 'users';
        } | null;
      };

      if (userData.data === null) {
        delete payload.data.relationships['user'];
      }
    }

    return payload;
  }
}

// DO NOT DELETE: this is how TypeScript knows how to look up your serializers.
declare module 'ember-data/types/registries/serializer' {
  export default interface SerializerRegistry {
    'vfd-contact-method': VfdContactMethodSerializer;
  }
}
