import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { later } from '@ember/runloop';
import { service } from '@ember/service';
import { isBlank } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency';
import type AuthzService from 'garaje/services/authz';
import type CohoService from 'garaje/services/coho';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type StatsigService from 'garaje/services/statsig';
import { adminRolesRoute } from 'garaje/utils/admin-routing';
import { getRoleIcon, RoleId } from 'garaje/utils/custom-roles';
import type { Role } from 'garaje/utils/custom-roles';
import { APP, WorkplaceEventNames } from 'garaje/utils/enums';
import { MatrixKind, displayDescriptionKeyMapping, displayNameKeyMapping } from 'garaje/utils/permission-sets';
import type { Matrix, MatrixItem, MatrixRow } from 'garaje/utils/permission-sets';

interface CreateRoleModel {
  role: Role;
  matrix: Matrix;
}

interface MatrixByGrid {
  grid: MatrixRow | undefined;
  products: Array<MatrixByProduct>;
  gridIndex: number;
}

export interface MatrixByProduct {
  product: MatrixRow | undefined;
  rows: Array<MatrixRow>;
}

class ManageCreateRoleController extends Controller {
  @service declare authz: AuthzService;
  @service declare router: RouterService;
  @service declare flashMessages: FlashMessagesService;
  @service declare coho: CohoService;
  @service declare statsig: StatsigService;

  declare model: CreateRoleModel;

  @tracked name: string = '';
  @tracked description: string = '';
  @tracked permissionSetIds: Array<string> = this.selectedPermissionSets;
  @tracked showUpsellModal = false;
  @tracked showRequestSentModal = false;

  initProperties(): void {
    // if editing a role, pre populate role name + description
    if (
      this.router.currentRouteName !== 'manage.roles' &&
      this.router.currentRouteName !== 'location-overview.manage.roles' &&
      !this.isCreate
    ) {
      this.name = this.model.role.name;
      this.description = this.model.role.description;
    }

    this.permissionSetIds = this.selectedPermissionSets;
  }

  get selectedPermissionSets(): Array<string> {
    const { matrix } = this.model;
    const selectedIds: Array<string> = [];

    if (matrix && matrix.rows?.length > 0) {
      matrix.rows.forEach((row) => {
        if (row.kind === MatrixKind.PERMISSION_SET && row.item && row.selected) {
          selectedIds.push(row.item.id);
        }
      });
    }

    return selectedIds;
  }

  // break down the matrix into a usable set of grids > products > categories > permission sets
  get matrixByGrids(): Array<MatrixByGrid> {
    const { matrix } = this.model;

    const matrixByGrids: Array<MatrixByGrid> = [];
    let currentGrid: MatrixByGrid;
    let products: Array<MatrixByProduct> = [];
    let currentProduct: MatrixByProduct;

    if (matrix && matrix.rows?.length > 0) {
      matrix.rows.forEach((row: MatrixRow, i: number) => {
        switch (row.kind) {
          case MatrixKind.GRID:
            if (currentGrid && Object.keys(currentGrid).length > 0) {
              // new grid, add everything to currentGrid
              products.push(currentProduct);
              currentGrid.products = products;
              matrixByGrids.push(currentGrid);
            }
            // reset
            currentGrid = {
              grid: row,
              products: [],
              gridIndex: i === 0 ? 0 : currentGrid.gridIndex + 1,
            };
            products = [];
            currentProduct = {
              product: undefined,
              rows: [],
            };
            break;
          case MatrixKind.PRODUCT:
            if (currentProduct && Object.keys(currentProduct).length > 0 && currentProduct.product) {
              // new product, add everything to currentProduct
              products.push(currentProduct);
            }
            // reset
            currentProduct = {
              product: row,
              rows: [],
            };
            break;
          case MatrixKind.CATEGORY:
          case MatrixKind.PERMISSION_SET:
          default:
            currentProduct.rows.push(row);
            if (i === matrix.rows.length - 1) {
              // last item in the matrix, push to matrixByGrids
              products.push(currentProduct);
              currentGrid.products = products;
              matrixByGrids.push(currentGrid);
            }
        }
      });
    }

    return matrixByGrids;
  }

  get iconPath(): string {
    const { role } = this.model;
    return role ? getRoleIcon(role.id) : '';
  }

  get routeBackTo(): string {
    return adminRolesRoute(this.router.currentRouteName, 'manage.roles');
  }

  get isCreate(): boolean {
    return this.router.currentURL.includes('create');
  }

  get isNotStartFromScratch(): boolean {
    const { role } = this.model;
    return role.id !== RoleId.COMPANY_START_FROM_SCRATCH && role.id !== RoleId.LOCATION_START_FROM_SCRATCH;
  }

  get copiedFrom(): string | null {
    return this.isNotStartFromScratch ? this.model.role.name : null;
  }

  upsellModalDescription(row: MatrixRow): string {
    let sku: string;
    let upsellTier: string;

    if (row.displayNameKey == 'grid.visitors.name') {
      sku = 'Visitors';
      upsellTier = 'Enterprise';
    } else {
      sku = 'Workplace';
      upsellTier = 'Premium Plus';
    }

    const result = `
      Control viewing and editing permissions for ${sku} features. To unlock custom admin roles for ${sku},
      please contact us to upgrade to our ${sku} ${upsellTier} plan.
    `;

    return result;
  }

  @action
  toggleUpsellModal(): void {
    this.showUpsellModal = !this.showUpsellModal;
  }

  @action
  displayRequestSentModal(): void {
    this.showUpsellModal = false;
    this.showRequestSentModal = true;
    later(() => {
      this.showRequestSentModal = false;
    }, 2500);
  }

  @action
  addPermission(item: MatrixItem): void {
    const permissionSetId: string = item.id;
    const permissionsToAdd: Array<string> = [permissionSetId];
    if (item.dependsOn && item.dependsOn.length > 0) {
      item.dependsOn.forEach((id) => {
        if (!this.permissionSetIds.includes(id)) {
          permissionsToAdd.push(id);
        }
      });
    }
    this.permissionSetIds = [...this.permissionSetIds, ...permissionsToAdd];
  }

  @action
  removePermission(item: MatrixItem): void {
    const permissionSetId: string = item.id;
    const permissionsToRemove: Array<string> = [permissionSetId];
    if (item.dependencyFor && item.dependencyFor.length > 0) {
      item.dependencyFor.forEach((id) => {
        if (this.permissionSetIds.includes(id)) {
          permissionsToRemove.push(id);
        }
      });
    }

    this.permissionSetIds = this.permissionSetIds.filter((id) => !permissionsToRemove.includes(id));
  }

  createRoleTask = dropTask(async () => {
    if (isBlank(this.name)) {
      this.flashMessages.showAndHideFlash('error', 'Role name is required');
    } else if (isBlank(this.description)) {
      this.flashMessages.showAndHideFlash('error', 'Role description is required');
    } else {
      try {
        const permissionSets = this.permissionSetIds.map((id) => {
          return { 'permission-set-id': id, effect: 'allow' };
        });
        await this.authz.createRole({
          name: this.name,
          description: this.description,
          'role-scope': this.model.role.roleScope,
          'legacy-role': this.model.role.legacyRole,
          'copied-from': this.copiedFrom,
          'permission-sets': permissionSets,
        });
        this.coho.sendEvent(WorkplaceEventNames.CUSTOM_ROLE_CREATED, { product: APP.WORKPLACE });
        this.statsig.logEvent(`coho_${WorkplaceEventNames.CUSTOM_ROLE_CREATED}`, null, { product: APP.WORKPLACE });
        this.flashMessages.showAndHideFlash('success', 'Admin role created');
        void this.router.transitionTo(this.routeBackTo);
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
        this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
      }
    }
  });

  updateRoleTask = dropTask(async () => {
    if (isBlank(this.name)) {
      this.flashMessages.showAndHideFlash('error', 'Role name is required');
    } else if (isBlank(this.description)) {
      this.flashMessages.showAndHideFlash('error', 'Role description is required');
    } else {
      try {
        const permissionSets = this.permissionSetIds.map((id) => {
          return { 'permission-set-id': id, effect: 'allow' };
        });
        await this.authz.updateRole(
          {
            name: this.name,
            description: this.description,
            'permission-sets': permissionSets,
          },
          this.model.role.id,
        );
        this.flashMessages.showAndHideFlash('success', 'Admin role updated');
        void this.router.transitionTo(this.routeBackTo);
      } catch (e) {
        console.error(e); // eslint-disable-line no-console
        this.flashMessages.showAndHideFlash('error', 'Something went wrong, please try again.');
      }
    }
  });

  renderGridName(row: MatrixRow): string {
    return displayNameKeyMapping[row.displayNameKey] || '';
  }

  renderGridDescription(row: MatrixRow): string {
    return displayDescriptionKeyMapping[row.displayDescriptionKey] || '';
  }

  reset(): void {
    this.name = '';
    this.description = '';
    this.permissionSetIds = [];
  }
}

export default ManageCreateRoleController;
