/* eslint-disable @typescript-eslint/await-thenable */
import { action, set } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask, task } from 'ember-concurrency';
import type { Task } from 'ember-concurrency';
import type DraftModel from 'garaje/models/draft';
import type CohoService from 'garaje/services/coho';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MapVersionsService from 'garaje/services/map-versions';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import { APP, WorkplaceEventNames } from 'garaje/utils/enums';
import { defer } from 'rsvp';

interface DraftsHeaderArgs {
  draft: DraftModel;
  saveDisabled: boolean;
  saveTask: Task<void, []>;
  reloadMapsRoute: () => void;
}

export default class DraftsHeader extends Component<DraftsHeaderArgs> {
  @service declare flashMessages: FlashMessagesService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare store: StoreService;
  @service declare state: StateService;
  @service declare router: RouterService;
  @service declare metrics: MetricsService;
  @service declare mapVersions: MapVersionsService;
  @service declare coho: CohoService;
  @service declare statsig: StatsigService;

  @tracked draftName: string = this.args.draft.name; // eslint-disable-line ember/no-tracked-properties-from-args

  @action
  logViewToCohoAndStatsig(): void {
    this.coho.sendEvent(WorkplaceEventNames.DRAFT_EDITED, { product: APP.WORKPLACE });
    this.statsig.logEvent(`coho_${WorkplaceEventNames.DRAFT_EDITED}`, null, {
      product: APP.WORKPLACE,
      location_id: this.state?.currentLocation?.id,
    });
  }

  @action
  updateDraftName(ev: InputEvent): void {
    const val = (<HTMLInputElement>ev.target).innerText;
    this.draftName = val;
  }

  @action
  onSaveClick(): void {
    set(this.args.draft, 'name', this.draftName);
    void this.args.saveTask.perform();
  }

  @dropTask
  showPublishConfirmationTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: DraftsHeader; abort?: () => void; continue?: () => Promise<void> }) {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        try {
          await this.context.args.draft.publish({
            data: { attributes: { 'published-by': this.context.currentAdmin.employee?.id }, type: 'drafts' },
          });
          await this.context.mapVersions.snapshotTask.perform(
            'draft-publish',
            await this.context.args.draft.originalAreaMap,
          );
          await this.context.args.reloadMapsRoute();
          this.context.metrics.trackEvent('Publishing Draft', {
            id: this.context.args.draft.id,
          });
          this.context.flashMessages.showAndHideFlash('success', 'Draft successfully published');
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(JSON.stringify(e));
          this.context.flashMessages.showAndHideFlash('error', 'Error publishing draft');
        }
        void this.context.router.transitionTo('spaces.maps.live');
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  @dropTask
  showSaveConfirmationTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: DraftsHeader; abort?: () => void; continue?: () => Promise<void> }) {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = async () => {
        try {
          await this.context.args.saveTask.perform();
        } catch (e) {
          // eslint-disable-next-line no-console
          console.error(JSON.stringify(e));
        }
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };

  publishTask = task({ drop: true }, async () => {
    if (!this.args.saveDisabled) {
      await this.showSaveConfirmationTask.perform();
    }
    await this.showPublishConfirmationTask.perform();
  });

  @dropTask
  showShareModalTask: {
    perform(): Generator<Promise<unknown>, unknown, unknown>;
  } = {
    *perform(this: { context: DraftsHeader; abort?: () => void; continue?: () => void }) {
      const deferred = defer();
      this.abort = () => {
        deferred.resolve(false);
      };
      this.continue = () => {
        deferred.resolve(true);
      };
      return yield deferred.promise;
    },
  };
}
