import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { isBlank, isEmpty } from '@ember/utils';
import { action, get, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { guidFor } from '@ember/object/internals';
import DropdownOption from 'garaje/models/dropdown-option';
import { APP, VisitorsEventNames } from 'garaje/utils/enums';
/**
 * @param {Boolean}                           isConditional
 * @param {Boolean}                           isDisabled
 * @param {Array}                             signInFields
 * @param {Changeset<SignInField>}            field
 * @param {Boolean}                           isRTL
 * @param {Function}                          deleteFieldAction
 * @param {Function}                          toggleRecalculateContainer
 * @param {Function}                          getDefaultStoreResponse
 * @param {Array}                             changesetFields
 * @param {Boolean}                           isConditionalParent
 */
export default class SignInFieldsDropDownFieldComponent extends Component {
  @service store;
  @service coho;
  inputId = 'input-' + guidFor(this);
  placeholder = 'Question';
  canDelete = true;
  draggable = true;

  @tracked isTextarea = false;
  @tracked textOptions;
  @tracked newOption = '';

  get options() {
    return get(this.args.field, 'options');
  }

  get numRows() {
    return Math.min(this.options.length, 14);
  }

  get isValidNewOption() {
    const cleanedValue = `${this.newOption ?? ''}`.trim();

    return cleanedValue.length > 0;
  }

  @action
  addConditional(kind, option) {
    const fieldTypes = [
      { name: 'Short answer', kind: 'text' },
      { name: 'Dropdown', kind: 'single-selection' },
    ];
    const fieldType = fieldTypes.find(({ kind: fieldKind }) => fieldKind === kind);

    // Create the conditional field
    const conditionalField = this.args.signInFields.createRecord({
      kind: fieldType.kind,
      required: get(this.args.field, 'required'),
      position: this.args.signInFields.length + 1,
      storeResponse: this.args.getDefaultStoreResponse(kind),
    });

    // Create the action associated
    const signInFieldAction = get(this.args.field, '_content.signInFieldActions').createRecord({
      actionableSignInField: conditionalField,
      signInFieldValue: option.id,
    });
    get(this.args.field, '_content.actionableSignInFields').pushObject(conditionalField);
    set(signInFieldAction, '_dropdownOption', option);
    set(this.args.field, 'showConditionals', true);
  }

  /* options: A list representing a dropdown options like:
   * [{ value: 'The Cure' }, { value: 'New Order' }]
   *
   * textOptions: Text representation of the options list, each line
   * represent an option, it is used for quick-editing only.
   */
  @action
  setTextOptions(options) {
    this.textOptions = options;
    this.convertTextToOptions();
  }

  @action
  toggleTextarea() {
    this.isTextarea = !this.isTextarea;

    if (this.isTextarea) {
      this.convertOptionsToText();
    } else {
      this.convertTextToOptions();
    }

    if (this.args.toggleRecalculateContainer) {
      this.args.toggleRecalculateContainer();
    }
  }

  @action
  toggleConditionals() {
    this.args.field.toggleProperty('showConditionals');
  }

  convertOptionsToText() {
    const textOptions = this.options.mapBy('value').compact();

    this.textOptions = textOptions.join('\n');
  }

  convertTextToOptions() {
    const textOptions = this.textOptions;
    // normalize linebreaks
    textOptions.replace(/(?:\r\n|\r)+/g, '\n');

    const newOptions = textOptions
      .split('\n')
      .filter((line) => !isBlank(line))
      .map((line) => {
        return new DropdownOption({ value: line.trim() });
      });
    set(this.args.field, 'options', newOptions);
  }

  @action
  reorderOptions(options) {
    options.forEach(function (opt, index) {
      set(opt, 'position', index);
    });
    set(this.args.field, 'options', options);
    return options;
  }

  insert(value) {
    const options = this.reorderOptions(this.options);
    const position = options.reduce((max, opt) => Math.max(max, opt.position), -1) + 1;

    options.pushObject(new DropdownOption({ value, position }));
    set(this.args.field, 'options', options);
    if (this.args.toggleRecalculateContainer) {
      this.args.toggleRecalculateContainer();
    }
  }

  insertOption(value = '') {
    const cleanedValue = `${value}`.trim();

    if (cleanedValue.length) {
      this.insert(cleanedValue);
    }
  }

  // handle keypress on the new option field, if the key is
  // "enter", then add the insert the new field.
  @action
  optionKeypressed(event) {
    const bubbleUp = this.stopSubmit(event);

    this.newOption = event.target.value;

    if (!bubbleUp) {
      this.insertOption(event.target.value);
      event.target.value = '';
      this.newOption = null;
      event.target.focus();
    }

    return bubbleUp;
  }

  @action
  simulateEnterOnNewOption({ target }) {
    this.insertOption(this.newOption);
    this.newOption = null;
    const input = target.closest('.dropdownFields')?.querySelector('[data-new-dp-field]');
    if (input) {
      input.value = '';
      input.focus();
    }
    this.coho.sendEvent(VisitorsEventNames.SignInFieldUpdated, { product: APP.VISITORS });
  }

  @action
  stopSubmit(event) {
    let bubbleUp = true;

    if (event.keyCode === 13) {
      event.preventDefault();
      event.stopPropagation();
      bubbleUp = false;
    }

    return bubbleUp;
  }

  @action
  validateOption({ key, newValue, content }) {
    let isValid = true;

    if (key === 'value' && isEmpty(newValue)) {
      isValid = false;
    } else {
      set(content, key, newValue);
    }

    return isValid;
  }

  @action
  modifyOption(option, newValue = '') {
    option.value = `${newValue}`.trim();
  }

  @action
  deleteOption(option) {
    const options = this.reorderOptions(this.options).removeObject(option);
    set(this.args.field, 'options', options);
  }
}
