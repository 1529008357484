import Controller from '@ember/controller';
import { action } from '@ember/object';
import { dependentKeyCompat } from '@ember/object/compat';
import { next } from '@ember/runloop';
import { service } from '@ember/service';
import { isPresent, isBlank } from '@ember/utils';
import { tracked } from '@glimmer/tracking';
import { enqueueTask, dropTask, restartableTask, timeout, waitForProperty } from 'ember-concurrency';
import config from 'garaje/config/environment';
import groupBy from 'garaje/utils/decorators/group-by';
import { EMPLOYEE_COUNT, IMPRESSION_NAMES, WorkplaceEventNames } from 'garaje/utils/enums';
import { LOCATION_TYPE_MAPPING } from 'garaje/pods/components/employees/directory/location-type-input/component';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import { alias, not, empty, notEmpty, gt, or, and, reads, equal, filterBy } from 'macro-decorators';
import { APP } from 'garaje/utils/enums';

export default class LocationOverviewEmployeesDirectoryController extends Controller {
  @service abilities;
  @service currentAdmin;
  @service exporter;
  @service featureFlags;
  @service googleSyncManager;
  @service localStorage;
  @service metrics;
  @service router;
  @service skinnyLocations;
  @service state;
  @service store;
  @service impressions;
  @service visitorsOnboarding;
  @service emptyStatePage;
  @service employeeDirectory;
  @service coho;
  @service statsig;

  @groupBy('sortedNamesDesc', 'firstCharacter') groupedContent;
  @alias('state.currentLocation') currentLocation;
  @alias('state.currentCompany') currentCompany;
  @alias('skinnyLocations.currentCompanyLocations') locations;
  @empty('name') isSearchEmpty;
  @notEmpty('name') hasSearch;
  @not('hideClearButton') showClearButton;
  @gt('employeeDirectory.employeesCount', 0) hasEmployees;
  @reads('currentCompany.directorySyncProvider') hasDirectorySync;
  @and('canManuallyAddEmployees', 'hasDirectorySync') hasSyncAndCanAddEmployees;
  @or('isSearchEmpty', 'loadPageTask.isRunning', 'resetEmployeesTask.isRunning') hideClearButton;
  @or(
    'hasEmployees',
    'hasSearch',
    'loadPageTask.isRunning',
    'resetEmployeesTask.isRunning',
    'documentStatusFilter',
    'manuallyAddedFilter',
    'selectedLocationIds',
    'hasSyncAndCanAddEmployees',
    'isAddingEmployee',
  )
  showEmployeesInfo;

  @reads('model.userDocumentTemplateConfigurations', () => []) userDocumentTemplateConfigurations;
  @filterBy('userDocumentTemplateConfigurations', 'active') activeDocumentTemplateConfigurations;
  @equal('state.currentLocation.employeesCsvUploadStatus', 'done') isCsvUploaded;

  queryParams = ['name', 'page', 'manuallyAddedFilter', 'documentStatusFilter'];
  isCypress = config.isCypress;

  @tracked page = 1;
  @tracked manuallyAddedFilter = '';
  @tracked documentStatusFilter = '';
  @tracked name = '';
  @tracked limit = 50;
  @tracked employeesToImport = null;
  @tracked showEmployeeGatingModal = false;
  @tracked showEmployeeActionModal = false;
  @tracked showPremiumFeatureModal = false;
  @tracked showEmployeeDeletionModal = false;
  @tracked showEmployeeImportModal = false;
  @tracked showConfirmDeleteModal = false;
  @tracked canCloseExperience = true;
  @tracked impressionsCount = 0;
  @tracked employeesToDelete = null;
  @tracked csvToDelete = null;
  @tracked selectedLocationIds = '';
  @tracked sortDirection = 'asc';
  @tracked columnsToShow = ['Primary location', 'Department', 'Phone number'];

  manuallyAddedFilterOptions = [
    { title: 'All employees', value: '' },
    // `value` booleans are strings because, as queryParams,
    // they are getting converted to string
    { title: 'Manually added', value: 'true' },
    { title: 'Synced employees', value: 'false' },
  ];

  documentStatusFilterOptions = [
    { title: 'Document status', value: '' },
    { title: 'Not submitted', value: 'not_uploaded' },
    { title: 'Pending review', value: 'review' },
    { title: 'Denied', value: 'denied' },
    { title: 'Approved', value: 'approved' },
  ];

  get canManuallyAddEmployees() {
    return this.isGlobalOverview ? this.abilities.can('manage employees') : this.abilities.can('manage all employees');
  }

  get employeeGatingEventProps() {
    return {
      company_id: this.currentCompany.id,
      employeeCount: this.employeeDirectory.totalEmployees,
      project: '50 Employees Feature Gating',
      product: 'employees',
      timestamp: Math.floor(new Date().getTime() / 1000),
    };
  }

  get columnOptions() {
    const primaryHeaders = ['Primary location', 'Department', 'Phone number'];
    const otherHeaders = ['Manager', 'Assistant(s)', 'In person or remote'];

    return [...primaryHeaders, ...otherHeaders].map((header) => {
      return {
        name: header,
        show: primaryHeaders.includes(header),
      };
    });
  }

  @dependentKeyCompat
  get sortedNamesDesc() {
    return this.employeeDirectory.employees.sortBy('name');
  }

  get isAddingEmployee() {
    return this.router.currentRouteName?.includes('employees.directory.add-employee');
  }

  get isLoadingAll() {
    return this.loadPageTask.isRunning && !this.loadNextPageTask.isRunning && isBlank(this.name);
  }

  get hasMorePages() {
    return this.page * this.limit < this.employeeDirectory.employeesCount;
  }

  get pageCount() {
    return Math.ceil(this.employeeDirectory.employeesCount / this.limit);
  }

  get noResultsFound() {
    return (
      !this.loadPageTask.isRunning &&
      this.employeeDirectory.employees?.length < 1 &&
      (this.name || this.selectedLocationIds || this.manuallyAddedFilter || this.documentStatusFilter)
    );
  }

  get isGlobalOverview() {
    return this.router.currentRouteName?.includes('location-overview');
  }

  get integrationsRoute() {
    const empIntegrationsRoute = this.isGlobalOverview
      ? 'location-overview.employees.integrations'
      : 'employees.integrations';
    return this.featureFlags.isEnabled('appStore') ? empIntegrationsRoute : 'integrations';
  }

  get showUpgradeButton() {
    return (
      !this.state.features?.canAccessUnlimitedEmployees &&
      this.featureFlags.isEnabled('employeeFeatureGating') &&
      !this.model.isConnectedToProperty
    );
  }

  get isBasicAndUnder50Employees() {
    return this.showUpgradeButton && 50 > this.employeeDirectory.totalEmployees;
  }

  get isBasicAndOver50Employees() {
    return this.showUpgradeButton && 49 < this.employeeDirectory.totalEmployees;
  }

  get ctaEventProperties() {
    return {
      cta_id: 'directory_sync_upgrade',
      cta_type: 'button',
      cta_clickable_type: 'button',
      cta_clickable_text: 'Upgrade to enable Directory Sync',
      cta_title: null,
      cta_body: null,
      cta_intent: 'upgrade',
    };
  }

  @action
  changeManuallyAddedFilter(option) {
    if (this.featureFlags.isEnabled('employee-directory-redesign')) {
      if (option.target.value !== 'true' && option.target.value !== 'false') {
        // reset the filter
        this.manuallyAddedFilter = '';
      } else {
        this.manuallyAddedFilter = option.target.value;
      }
    } else {
      this.manuallyAddedFilter = option.value;
    }
    this.page = 1;
    this.loadPageTask.perform();
    this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees.directory`);
  }

  @action
  changeDocumentStatusFilter(option) {
    if (this.featureFlags.isEnabled('employee-directory-redesign')) {
      if (option.target.value === 'on') {
        // reset the filter
        this.documentStatusFilter = '';
      } else {
        this.documentStatusFilter = option.target.value;
      }
    } else {
      this.documentStatusFilter = option.value;
    }
    this.page = 1;
    this.loadPageTask.perform();
    this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees.directory`);
  }

  @action
  nextPage() {
    return this.loadNextPageTask.perform();
  }

  @action
  sync() {
    this.googleSyncManager.sync();
  }

  @action
  resetSearch() {
    this.mutateName.perform('');
  }

  @action
  changeSortDirection(sortField, sortDirection) {
    this.sortDirection = sortDirection;
    this.loadPageTask.perform();
  }

  @action
  setSelectedLocationIds(options) {
    // already selected ids
    let ids = this.selectedLocationIds.split(',').filter((el) => isPresent(el));

    // add an id of a clicked location if it's not already selected
    options.forEach((option) => {
      const optionId = option.id;
      ids = ids.includes(optionId) ? ids.filter((id) => id !== optionId) : [...ids, optionId];
    });

    this.selectedLocationIds = ids.join(',');

    this.page = 1;
    this.loadPageTask.perform();
  }

  @action
  closeGatingExperience() {
    if (this.showEmployeeGatingModal) {
      this.showEmployeeGatingModal = false;
      this.metrics.trackEvent('50 Employee Gating Modal Closed', this.employeeGatingEventProps);
      if (this.impressionsCount === 3) {
        this.showEmployeeActionModal = true;
      }
    } else if (this.showPremiumFeatureModal) {
      this.showPremiumFeatureModal = false;
      this.metrics.trackEvent('Premium Feature Modal Closed', this.employeeGatingEventProps);
    }
  }

  @action
  closeEmployeeActionModal() {
    this.showEmployeeActionModal = false;
    this.metrics.trackEvent('Employee Action Modal Closed', this.employeeGatingEventProps);
  }

  @action
  closeEmployeeGatingModal() {
    if (this.showEmployeeGatingModal) {
      this.showEmployeeGatingModal = false;
      this.metrics.trackEvent('50 Employee Gating Modal Closed', this.employeeGatingEventProps);

      const canManage = this.isGlobalOverview
        ? this.abilities.can('manage all employees')
        : this.abilities.can('manage employees');

      if (!this.currentAdmin.isBillingAdmin && canManage) {
        if (this.featureFlags.isEnabled('employeeGatingImport')) {
          this.showEmployeeImportModal = true;
          this.metrics.trackEvent('50 Employee Gating Import Modal Shown', this.employeeGatingEventProps);
        } else {
          this.showEmployeeDeletionModal = true;
          this.metrics.trackEvent('50 Employee Gating Deletion Modal Shown', this.employeeGatingEventProps);
        }
      }
    } else if (this.showPremiumFeatureModal) {
      this.showPremiumFeatureModal = false;
      this.metrics.trackEvent('Premium Feature Modal Closed', this.employeeGatingEventProps);
    }
  }

  @action
  closeEmployeeImportModal(data) {
    this.showEmployeeImportModal = false;
    this.employeesToImport = data;
    this.metrics.trackEvent('50 Employee Gating Import Modal Closed', this.employeeGatingEventProps);
    this.showEmployeeDeletionModal = true;
    this.metrics.trackEvent('50 Employee Gating Deletion Modal Shown', this.employeeGatingEventProps);
  }

  @action
  closeGatingViaImport() {
    this.showEmployeeImportModal = false;
    this.employeeDirectory.totalEmployees = null;
    this.loadPageTask.perform();
    this.metrics.trackEvent(
      '50 Employee Gating Import Modal Closed Via Import of Employees',
      this.employeeGatingEventProps,
    );
  }

  @action
  closeEmployeeDeletionModal(employeeArray, isCsv) {
    this.showEmployeeDeletionModal = false;
    this.metrics.trackEvent('50 Employee Gating Deletion Modal Closed', this.employeeGatingEventProps);
    if (isPresent(employeeArray) && !isCsv) {
      this.employeesToDelete = employeeArray;
      this.showConfirmDeleteModal = true;
    } else if (isPresent(employeeArray) && isCsv) {
      this.csvToDelete = employeeArray;
      this.showConfirmDeleteModal = true;
    }
    this.metrics.trackEvent('Delete Confirmation Modal Shown', this.employeeGatingEventProps);
  }

  @action
  closeConfirmDeleteModal(csv) {
    if (csv) {
      this.employeesToImport = csv;
    }
    this.showConfirmDeleteModal = false;
    this.metrics.trackEvent('Delete Confirmation Modal Closed', this.employeeGatingEventProps);
    this.showEmployeeDeletionModal = true;
  }

  @action
  afterDelete(updatedCsv) {
    // refresh model
    if (updatedCsv) {
      this.employeesToImport = updatedCsv;
      this.showConfirmDeleteModal = false;
      this.showEmployeeDeletionModal = true;
    } else if (
      this.employeesToDelete &&
      this.employeeDirectory.totalEmployees - this.employeesToDelete.length > EMPLOYEE_COUNT.EMPLOYEE_LIMIT
    ) {
      this.showConfirmDeleteModal = false;
      this.showEmployeeDeletionModal = true;
      this.loadPageTask.perform();
    } else {
      this.showConfirmDeleteModal = false;
      if (this.employeesToDelete) {
        this.loadPageTask.perform();
      } else {
        this.afterDeleteTask.perform();
      }
    }
    this.employeeDirectory.totalEmployees = null;
    this.metrics.trackEvent('50 Employee Gating Deletion Modal Shown', this.employeeGatingEventProps);
  }

  @action
  exportEmployees() {
    const url = urlBuilder.v3.employees.exportUrl(this.currentCompany.id);

    const settings = { data: { filter: {} } };
    if (!this.isGlobalOverview) {
      settings.data.filter.locations = this.currentLocation.id;
    }

    this.exporter.csvFromUrl(url, settings, 'employees-export');
    this.coho.sendEvent(WorkplaceEventNames.EXPORT_EMPLOYEES, { product: APP.WORKPLACE });
    this.statsig.logEvent(`coho_${WorkplaceEventNames.EXPORT_EMPLOYEES}`, null, {
      product: APP.WORKPLACE,
      location_id: this.state?.currentLocation?.id,
    });
  }

  @action
  routeToEmployeeEdit(employeeId) {
    if (this.isGlobalOverview) {
      this.router.transitionTo('location-overview.employees.directory.employee', employeeId);
    } else {
      this.router.transitionTo('employees.directory.employee', employeeId);
    }
  }

  @action
  updateColumns(column) {
    if (column.show) {
      this.columnsToShow = [...this.columnsToShow, column.name];
    } else {
      this.columnsToShow = this.columnsToShow.filter((name) => name !== column.name);
    }
  }

  @action
  logCTAViewedEvent() {
    this.metrics.trackEvent('CTA Viewed', this.ctaEventProperties);
  }

  @action
  logCTAClickedEvent() {
    this.metrics.trackEvent('CTA Clicked', this.ctaEventProperties);
  }

  @action
  trackUpgradeToPremiumClick() {
    this.metrics.trackEvent('Upgrade To Premium Clicked', this.employeeGatingEventProps);
  }

  mutateName = restartableTask(async (name) => {
    await timeout(zft(500));

    this.page = 1;
    this.name = name;
    await this.loadPageTask.perform();
    this.router.transitionTo(`${this.isGlobalOverview ? 'location-overview.' : ''}employees.directory`);
  });

  getEmployeeGatingModal = dropTask(async () => {
    const employees = await this.store.query('employee', this.buildQuery());
    this.employeeDirectory.totalEmployees = employees.meta?.total;
    if (this.featureFlags.isEnabled('employeeFeatureGating') && !this.model.isConnectedToProperty) {
      if (this.employeeDirectory.totalEmployees > 50 && !this.state.features?.canAccessUnlimitedEmployees) {
        if (
          this.state.vrSubscription &&
          this.state.vrSubscription.isBasicPlan &&
          this.featureFlags.isEnabled('employeeGatingHardSell')
        ) {
          await this.impressions.postImpression.perform(IMPRESSION_NAMES.EMPLOYEE_GATING);
          const impressions = await this.impressions.getImpressions.perform(IMPRESSION_NAMES.EMPLOYEE_GATING);
          this.impressionsCount = impressions.length;
          this.impressionsCount > 3 ? (this.canCloseExperience = false) : null;
          if (!this.canCloseExperience) {
            this.metrics.trackEvent('User unable to close employee gating experience', this.employeeGatingEventProps);
          }
        }
        this.showEmployeeGatingModal = true;
        this.metrics.trackEvent('50 Employee Gating Modal Shown', this.employeeGatingEventProps);
      } else if (this.employeeDirectory.totalEmployees > 50 && this.onTrial) {
        const localStorageModalShown = this.localStorage.getItem('premium-feature-gating-modal-shown');
        const growthServiceModalShown = await this.impressions.getImpressions.perform(
          IMPRESSION_NAMES.PREMIUM_FEATURE_GATING_MODAL_SHOWN,
        );
        const hasSeenModal = localStorageModalShown || growthServiceModalShown;
        if (!hasSeenModal) {
          this.showPremiumFeatureModal = true;
          await this.impressions.postImpression.perform(IMPRESSION_NAMES.PREMIUM_FEATURE_GATING_MODAL_SHOWN);
          this.metrics.trackEvent('Premium Feature Modal Shown', this.employeeGatingEventProps);
        }
        if (localStorageModalShown && !growthServiceModalShown) {
          await this.impressions.postImpression.perform(IMPRESSION_NAMES.PREMIUM_FEATURE_GATING_MODAL_SHOWN);
        }
      }
    }
  });

  loadNextPageTask = dropTask(async () => {
    if (this.hasMorePages) {
      try {
        this.page++;
        await this.loadPageTask.perform();
      } catch (e) {
        this.page--;
      }
    }
  });

  loadIndividualPageTask = dropTask(async (pageNumber) => {
    const oldPage = this.page;
    this.page = pageNumber;
    try {
      await this.loadPageTask.perform();
    } catch (e) {
      this.page = oldPage;
    }
  });

  loadPageTask = enqueueTask(async () => {
    const employees = await this.store.query('employee', this.buildQuery());
    const employeesArray = employees.toArray();

    if (this.featureFlags.isEnabled('employee-directory-redesign')) {
      this.employeeDirectory.employees = employeesArray;
    } else {
      if (this.page > 1) {
        this.employeeDirectory.employees.pushObjects(employeesArray);
      } else {
        this.employeeDirectory.employees = employeesArray;
      }
    }

    this.employeeDirectory.employeesCount = employees.meta?.total;

    if (!this.employeeDirectory.totalEmployees) {
      this.employeeDirectory.totalEmployees = employees.meta?.total;
    }

    if (this.visitorsOnboarding.showVideoWalkthrough) {
      this.visitorsOnboarding.loadEmployeesTask.perform();
    }

    return employees;
  });

  // Resets the state of the controller so is starts loading employees
  // and deleted employees from page 1
  //
  // Whenever we access this route or want to reset the list of
  // employees we call this task so it does that.
  //
  // The reason for using a task here is that it help us drive the
  // state in the template, so we don't show the "no users screen" until
  // we are sure that we fetch the first page of data.
  //
  // TODO: Rewrite this controller as a stateless component.
  //
  resetEmployeesTask = dropTask(async () => {
    await new Promise((resolve) => {
      next(() => {
        this.page = 1;
        this.employeeDirectory.employeesCount = 0;
        this.employeeDirectory.totalEmployees = null;

        resolve(this.loadPageTask.perform());
      });
    });
  });

  afterDeleteTask = dropTask(async () => {
    await waitForProperty(this, 'isCsvUploaded');
    this.loadPageTask.perform();
  });

  buildQuery() {
    const limit = this.limit;
    const offset = (this.page - 1) * limit;
    const params = {};
    const { activeDocumentTemplateConfigurations } = this;

    params.page = { limit, offset };

    params.filter = { deleted: false };

    if (!this.isGlobalOverview) {
      params.filter.locations = this.currentLocation.id;
    }

    if (this.featureFlags.isEnabled('employee-directory-redesign') && isPresent(this.selectedLocationIds)) {
      params.filter.defaultLocationId = this.selectedLocationIds.split(',');
    }

    if (isPresent(this.name)) {
      params.filter['query'] = this.name;
    }

    if (this.manuallyAddedFilter) {
      params.filter['manually-added'] = this.manuallyAddedFilter;
    }

    if (this.documentStatusFilter) {
      params.filter['document-approval-status'] = this.documentStatusFilter;
      params.filter['document-identifier'] = activeDocumentTemplateConfigurations.mapBy('identifier');
    }

    params.include = 'assistants';

    if (this.sortDirection === 'desc') {
      params.sort = '-name';
    } else {
      params.sort = 'name';
    }

    return params;
  }

  defaultLocationName = (defaultLocationId) => {
    return this.locations.find((location) => location.id == defaultLocationId.toString())?.name || '-';
  };

  displayAssistants(assistants) {
    return assistants
      .map((assistant) => {
        return assistant.name;
      })
      .join(', ');
  }

  displayLocationType(locationType) {
    return LOCATION_TYPE_MAPPING[locationType] || '-';
  }
}
