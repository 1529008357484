import { action } from '@ember/object';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { formatInTimeZone } from 'date-fns-tz';
import type AbilitiesService from 'ember-can/services/abilities';
import { task } from 'ember-concurrency';
import type { Task } from 'ember-concurrency';
import type Invite from 'garaje/models/invite';
import type StateService from 'garaje/services/state';

interface VisitorsApprovalsTableRowArgs {
  approveInviteTask: Task<void, [Invite]>;
  columns: string[];
  denyInviteTask: Task<void, [Invite]>;
  hideReviewButtons: boolean; // whether Approve/Deny buttons should _not_ be shown
  invite: Invite;
  isSelected: boolean; // whether this invite is "selected" (for bulk actions)

  selectOrDeselectInvite: (invite: Invite) => unknown;
}

// map "source" values from an invite's "report" to a friendly display name
const REPORT_SOURCE_MAP: { [key: string]: string } = {
  blacklist: 'Blocklist',
  'capacity-screening': 'Capacity screening',
  'id-scanning': 'ID scanning',
  'identity-verification': 'Identity verification',
  'pre-registration-required': 'Pre-registration required',
  'property-invite-approval': 'Outgoing property invite',
  'requires-approval': 'Outgoing invite',
  'temperature-screening': 'Temperature screening',
  'visitor-documents': 'Document signing required',
  'visual-compliance': 'Visual Compliance match',
};

const DEFAULT_TIMEZONE = 'Etc/UTC'; // used if currentLocation is not present or is missing a timezone

const EXPECTED_ARRIVAL_DATE_FORMAT = 'MMM d, yyyy';
const EXPECTED_ARRIVAL_TIME_FORMAT = 'h:mm aaa';

export default class VisitorsApprovalsTableRow extends Component<VisitorsApprovalsTableRowArgs> {
  @service declare abilities: AbilitiesService;
  @service declare state: StateService;

  get expectedArrivalDate(): string | null {
    const invite = this.args.invite;
    return invite.expectedArrivalTime
      ? formatInTimeZone(
          invite.expectedArrivalTime,
          this.state.currentLocation?.timezone ?? DEFAULT_TIMEZONE,
          EXPECTED_ARRIVAL_DATE_FORMAT,
        )
      : null;
  }

  get expectedArrivalTime(): string | null {
    const invite = this.args.invite;
    return invite.expectedArrivalTime
      ? formatInTimeZone(
          invite.expectedArrivalTime,
          this.state.currentLocation?.timezone ?? DEFAULT_TIMEZONE,
          EXPECTED_ARRIVAL_TIME_FORMAT,
        )
      : null;
  }

  get approvalTypes(): string {
    const allApprovalTypes = (this.args.invite.approvalStatus?.report || [])
      .filter((report) => {
        if (!report.reviewable) return false;
        // `report.status` is only present on blocklist reports, not others
        if (report.source === 'blacklist' && report.status !== 'review') return false;
        if (report.source === 'visual-compliance' && report.result !== 'fail') return false;
        // conditional rules never screen to a review state
        if (report.source === 'conditional-rule') return false;
        return true;
      })
      .map((report) => REPORT_SOURCE_MAP[report.source] || report.source || 'Other');

    const uniqueApprovalTypes = new Set(allApprovalTypes);
    return [...uniqueApprovalTypes].join(', ');
  }

  get canReviewInvite(): boolean {
    return this.abilities.can('review entry-approval', {
      report: this.args.invite.approvalStatus?.failedReport,
      context: 'location',
    });
  }

  // CSS to use for padding for table cells; when we're displaying a "Host" field, use less
  // padding so the row isn't excessively taller than other rows w/o Host fields
  get paddingClass(): string {
    return this.args.invite.host ? 'py-2' : 'py-4';
  }

  get reviewButtonsHidden(): boolean {
    return this.args.hideReviewButtons || !this.canReviewInvite;
  }

  get reviewButtonsDisabled(): boolean {
    return this.reviewButtonsHidden || this.approveInviteTask.isRunning || this.denyInviteTask.isRunning;
  }

  /**
   * These component-local versions of tasks exist so we can get instance-specific state.
   * The actual logic lives at a higher level so that, when invoked with `.unlinked()`, it all
   * executes; otherwise, once the reviewed invite was reloaded (either with an `invite.reload()`
   * call or by the pubnub subscription that gets notified of changes to invites and pushes them
   * into the store out-of-band), this component gets torn down and the task is aborted before,
   * e.g., the flash message is shown. By keeping that logic out of this component, we don't
   * have to worry about that.
   * The instance-local state means that we can disable this invite's Approve/Deny buttons when
   * approveInviteTask or denyInviteTask is running without having to track state ourselves at
   * the higher level, or ended up disabling the buttons for _all_ invites instead of this one.
   */

  approveInviteTask = task(async (): Promise<void> => {
    await this.args.approveInviteTask.unlinked().perform(this.args.invite);
  });

  denyInviteTask = task(async (): Promise<void> => {
    await this.args.denyInviteTask.unlinked().perform(this.args.invite);
  });

  // local helper
  @action
  showColumn(columnName: string): boolean {
    return this.args.columns.includes(columnName);
  }
}
