/* eslint-disable ember/no-computed-properties-in-native-classes */
import { A } from '@ember/array';
import type NativeArray from '@ember/array/-private/native-array';
import { action, computed, get, setProperties, set } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { isEmpty, isPresent, isBlank } from '@ember/utils';
import type { AsyncHasMany } from '@ember-data/model';
import type StoreService from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type { DetailedChangeset } from 'ember-changeset/types';
import type { Task } from 'ember-concurrency';
import { timeout, task, dropTask, restartableTask } from 'ember-concurrency';
import { pluralize } from 'ember-inflector';
import type AbstractFlowModel from 'garaje/models/abstract/abstract-flow';
import DropdownOption from 'garaje/models/dropdown-option';
import type FlowModel from 'garaje/models/flow';
import type InviteModel from 'garaje/models/invite';
import type LocationModel from 'garaje/models/location';
import type SignInFieldModel from 'garaje/models/sign-in-field';
import type SkinnyLocationModel from 'garaje/models/skinny-location';
import type SubscriptionModel from 'garaje/models/subscription';
import type UserDatum from 'garaje/models/user-datum';
import type AjaxFetchService from 'garaje/services/ajax-fetch';
import type CurrentLocationService from 'garaje/services/current-location';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type LocationFeatureFlagsService from 'garaje/services/location-feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type { EmployeeSearcherTask } from 'garaje/utils/employees-searcher';
import employeesSearcherTask from 'garaje/utils/employees-searcher';
import { PURPOSE_OF_VISIT } from 'garaje/utils/enums';
import urlBuilder from 'garaje/utils/url-builder';
import zft from 'garaje/utils/zero-for-tests';
import type Handsontable from 'handsontable';
import type { GridSettings } from 'handsontable';
import type { SinglePayload } from 'jsonapi/response';
import _uniq from 'lodash/uniq';
import moment from 'moment-timezone';
import Papa from 'papaparse';
import { localCopy } from 'tracked-toolbox';
import { v4 as uuid } from 'uuid';

type ExtendedGridSettings = GridSettings & {
  csvHeader: string;
  timeFormat?: string;
  datePickerConfig?: { minDate: Date };
};

interface HandsontableConfig {
  validators: { DateValidator: (this: ExtendedGridSettings, date: Date, cb: (isValid: boolean) => void) => void };
}

type BulkInvitePayload = SinglePayload<{
  successRows: number[];
  errorRows: number[];
  bulkInviteId: string;
}>;

// In other places, `@ember/string/htmlSafe` is sufficient,
// but because we are forwarding these strings to another
// javascript library, we need to physically escape the string
const escapeHTML = (unsafeStr: string) => {
  return unsafeStr
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&#39;')
    .replace(/\./g, '&#46;');
};

const DATE_FORMAT = 'MM/DD/YYYY';
const TIME_FORMAT = 'h:mm a';
const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

const textValidator = function (this: GridSettings, text: string | undefined, cb: (isValid: boolean) => void) {
  cb(this.allowEmpty || (text ? text.length > 0 : false));
};

const positiveIntegerValidator = function (value: string, cb: (isValid: boolean) => void) {
  const number = Number(value);

  if (number > -1 && Math.floor(number) === number) {
    cb(true);
  } else {
    cb(false);
  }
};

const STATIC_COL_HEADERS = ['Arrival date (MM/DD/YYYY)', 'Arrival time', 'Full name', 'Visitor email address'];

// We use functions instead of static lists here because the columns and date validator both rely on
// handsontable, which is passed in as a component property and therefore not available until
// the component is actually rendered.
const createDateValidator = (handsontable: HandsontableConfig) => {
  return function (this: ExtendedGridSettings, date: Date, cb: (isValid: boolean) => void) {
    handsontable.validators.DateValidator.call(this, date, (isValid) => {
      if (!isValid) {
        cb(false);
        return;
      }

      const mDate = moment(date, DATE_FORMAT);
      cb(mDate.isSameOrAfter(new Date(), 'day'));
    });
  };
};

const createStaticColumns = (handsontable: HandsontableConfig, isEmailRequired = false): ExtendedGridSettings[] => {
  return [
    {
      allowEmpty: false,
      correctFormat: true,
      csvHeader: 'expected_arrival_time', // eslint-disable-line camelcase
      data: 'arrivalDate',
      dateFormat: DATE_FORMAT,
      datePickerConfig: {
        minDate: new Date(),
      },
      type: 'date',
      validator: createDateValidator(handsontable),
    },
    {
      allowEmpty: false,
      correctFormat: true,
      csvHeader: 'expected_arrival_time', // eslint-disable-line camelcase
      data: 'arrivalTime',
      timeFormat: TIME_FORMAT,
      type: 'time',
    },
    {
      allowEmpty: false,
      csvHeader: 'invitee_name', // eslint-disable-line camelcase
      data: 'fullName',
      type: 'text',
      validator: textValidator,
    },
    {
      allowEmpty: !isEmailRequired,
      csvHeader: 'invitee_email', // eslint-disable-line camelcase
      data: 'email',
      type: 'text',
      validator(val: string, cb) {
        if (isBlank(val)) {
          cb(isEmailRequired ? false : true);
        } else {
          cb(/@/.test(val));
        }
      },
    },
  ];
};

interface SpreadsheetPreregComponentSignature {
  Args: {
    changeset: DetailedChangeset<InviteModel>;
    flows: FlowModel[];
    isEditInvite?: boolean;
    locations: LocationModel[];
    locationIsConnectedToProperty?: boolean;
    onLocationSelectedTask: Task<void, [LocationModel]>;
    selectedFlow: FlowModel;
    handsontable: HandsontableConfig;
    onFlowChanged?: (flowName: string, changeset: DetailedChangeset<InviteModel>) => void;
    vrSubscription: SubscriptionModel;
  };
}

export default class SpreadsheetPreregComponent extends Component<SpreadsheetPreregComponentSignature> {
  @service declare ajaxFetch: AjaxFetchService;
  @service declare currentLocation: CurrentLocationService;
  @service declare flashMessages: FlashMessagesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare locationFeatureFlags: LocationFeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare store: StoreService;

  @tracked bulkInviteId: string | null = null; // Id used to track batches across failed uploads
  @tracked errorRows?: number[];
  @tracked flowNameToChangeTo?: FlowModel;
  @tracked groupName = '';
  @tracked hasEmail = false;
  @tracked hotInstance!: Handsontable;
  @tracked includeGroupNameInEmail = true;
  @tracked notifyVisitor = false;
  @tracked rowLength = 0;
  @tracked selectedLocation?: LocationModel;
  @tracked showConfirmVisitorTypeChangeModal = false;
  @tracked showPreregistrationEmailConfirmation = false;
  @tracked staticColumns;

  @localCopy('args.flows', []) flows!: FlowModel[];

  constructor(owner: unknown, args: SpreadsheetPreregComponentSignature['Args']) {
    super(owner, args);
    this.selectedLocation = this.currentLocation.location;
    this.staticColumns = createStaticColumns(this.args.handsontable, this.isEmailRequired);
  }

  @readOnly('selectedFlow.signInFieldPage.signInFields') signInFields?: SignInFieldModel[];

  get secondaryLocations(): LocationModel[] | AsyncHasMany<LocationModel> {
    return this.args.changeset.childInviteLocations || [];
  }

  get shouldShowSecondaryLocations(): boolean {
    return (
      this.featureFlags.isEnabled('multi-location-group-invites') &&
      !!this.state.features?.canAccessMultiLocationInvites &&
      this.args.locations.length > 1
    );
  }

  get locationSelectorId(): string {
    return `${guidFor(this)}-location-select`;
  }

  get flowSelectorId(): string {
    return `${guidFor(this)}-flow-select`;
  }

  get secondaryLocationSelectorId(): string {
    return `${guidFor(this)}-secondary-locations-select`;
  }

  get showMultiLocationToolTip(): boolean {
    return !(<FlowModel>this.selectedFlow)?.isGlobalChild && !this.args.isEditInvite;
  }

  @computed(
    'flows.@each.name',
    'purposeOfVisit.value',
    'args.changeset.flowName',
    'state.currentLocation.employeeScreeningFlow.id',
  )
  get selectedFlow(): AbstractFlowModel | null | undefined {
    // the flow rel is always correct and might not match the flowName or purpose of visit
    // eslint-disable-next-line ember/use-ember-get-and-set
    const flowId = this.args.changeset.get('flow.id');
    if (!flowId) {
      return null;
    }

    if (this.purposeOfVisit.value === PURPOSE_OF_VISIT.EMPLOYEE_REGISTRATION) {
      return this.state.currentLocation.employeeScreeningFlow;
    }

    return this.flows.find((flow) => flow.id === flowId);
  }

  @computed('args.changeset.location.id', 'selectedFlow.globalFlow.locations.[]')
  get optionsForSecondaryLocations(): Promise<(LocationModel | null)[]> | unknown[] {
    // eslint-disable-next-line @typescript-eslint/no-misused-promises
    if (!(<FlowModel>this.selectedFlow)?.globalFlow) {
      return [];
    }
    // eslint-disable-next-line ember/use-ember-get-and-set
    const primaryLocationId = this.args.changeset.location.get('id');
    const locationsRel = <
      AsyncHasMany<SkinnyLocationModel> | undefined // eslint-disable-next-line ember/no-get
    >get((<FlowModel>this.selectedFlow).globalFlow, 'locations');
    if (!locationsRel) return [];

    return locationsRel.then((skinnyLocations) => {
      return skinnyLocations
        .filter((loc) => loc.id !== primaryLocationId)
        .map((location) => this.store.peekRecord('location', location.id));
    });
  }

  @computed('signInFields.[]')
  get customFields(): NativeArray<SignInFieldModel> {
    return A(this.signInFields?.filter((field) => !['name', 'host', 'email'].includes(field.identifier))).sortBy(
      'position',
    );
  }

  @computed('signInFields.[]')
  get hostField(): SignInFieldModel | undefined {
    return A(this.signInFields).findBy('identifier', 'host');
  }

  @computed('canSetPropertyNotes', 'customFields.[]', 'hostField', 'selectedFlow.additionalGuests')
  get colHeaders(): string[] {
    const colHeaders = STATIC_COL_HEADERS.slice();

    const hostField = this.hostField;
    if (hostField) {
      const { name, localized, required } = hostField;
      if (required) {
        colHeaders.push(localized || name);
      } else {
        colHeaders.push(`${localized || name} (optional)`);
      }
    }

    if ((<FlowModel>this.selectedFlow).additionalGuests) {
      colHeaders.push('Additional visitors');
    }

    this.customFields.forEach((field) => {
      const { name, localized, kind } = field;
      if (kind === 'phone') {
        colHeaders.push('Visitor phone number');
      } else {
        colHeaders.push(localized || name);
      }
    });

    colHeaders.push('Private notes');
    if (this.canSetPropertyNotes) {
      colHeaders.push('Shared notes');
    }
    return colHeaders.map((h) => escapeHTML(h));
  }

  @computed(
    'canSetPropertyNotes',
    'customFields.[]',
    'hostField',
    'searchEmployeesTask',
    'selectedFlow.additionalGuests',
    'staticColumns',
  )
  get columns(): ExtendedGridSettings[] {
    const columns = this.staticColumns.slice();

    const hostField = this.hostField;
    if (hostField) {
      columns.push({
        allowEmpty: !hostField.required,
        csvHeader: 'host_name',
        data: 'host',
        type: 'autocomplete',
        // @ts-ignore
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        source: (term: string, cb: () => void) => this.searchEmployeesTask.perform(term, cb),
        validator: textValidator,
      });
    }

    if ((<FlowModel>this.selectedFlow).additionalGuests) {
      columns.push({
        allowEmpty: true,
        csvHeader: 'additional_guests',
        data: 'additionalGuests',
        type: 'text',
        validator: positiveIntegerValidator,
      });
    }

    this.customFields.forEach((field) => {
      const { name, required, kind } = field;
      const fieldOptions = field.options;
      let options: string[] = [];
      if (typeof fieldOptions === 'object' && fieldOptions.length && fieldOptions[0] instanceof DropdownOption) {
        options = fieldOptions.mapBy('value');
      } else {
        options = <string[]>(<unknown>fieldOptions);
      }
      columns.push({
        allowEmpty: !required,
        csvHeader: name,
        data: escapeHTML(name),
        source: kind === 'single-selection' ? options : [],
        type: kind === 'single-selection' ? 'dropdown' : 'text',
      });
    });

    columns.push({
      allowEmpty: true,
      csvHeader: 'private_notes',
      data: 'privateNotes',
      type: 'text',
    });

    if (this.canSetPropertyNotes) {
      columns.push({
        allowEmpty: true,
        csvHeader: 'property_notes',
        data: 'propertyNotes',
        type: 'text',
      });
    }

    return columns;
  }

  @computed('rowLength', 'submit.isRunning')
  get isSubmitDisabled(): boolean {
    return this.rowLength === 0 || this.submit.isRunning;
  }

  @computed('rowLength', 'hasEmail', 'submit.isRunning')
  get isSendPreRegEmailDisabled(): boolean {
    const hasNoRows = this.rowLength === 0;
    const isRunning = this.submit.isRunning;
    const hasNoEmail = !this.hasEmail;
    return hasNoRows || isRunning || hasNoEmail;
  }

  // eslint-disable-next-line ember/require-computed-property-dependencies
  @computed('args.changeset.{userData.@each.field,flowName}')
  get purposeOfVisit(): UserDatum | Partial<UserDatum> {
    const flow = this.args.changeset.flow;
    const userData = this.args.changeset.userData || A();
    const flowName = (<FlowModel>flow)?.name || this.args.changeset.flowName;

    return userData.findBy('field', 'Purpose of visit') || { field: 'Purpose of visit', value: flowName };
  }

  get canSetPropertyNotes(): boolean | undefined {
    return this.args.locationIsConnectedToProperty;
  }

  get emailSignInField(): SignInFieldModel | undefined {
    return this.signInFields?.find((field) => field.isEmail);
  }

  get flowOptions(): NativeArray<FlowModel> {
    return A(this.flows).rejectBy('isProtect').sortBy('position');
  }

  get isEmailRequired(): boolean {
    if (
      !(
        this.featureFlags.isEnabled('visitors-required-email-field') ||
        this.locationFeatureFlags.isEnabled('visitors-required-email-field-by-location')
      )
    )
      return false;

    return !!this.emailSignInField?.required;
  }

  @(employeesSearcherTask({
    filter: {
      deleted: false,
      excludeHidden: true,
    },
    prefix: true,
  }).restartable())
  _searchEmployeesTask!: EmployeeSearcherTask;

  submit = dropTask(async () => {
    this.flashMessages.hideAll();

    const hotInstance = this.hotInstance;

    const headers = _uniq(this.columns.map((c) => c.csvHeader));
    headers.unshift('Purpose of visit');

    const flowName = this.selectedFlow?.name;

    const data = A(
      hotInstance.getData().map((row: [string, string, string, ...string[]], i) => {
        if (hotInstance.isEmptyRow(i)) {
          return;
        }

        const additionalGuestsIndex = this.colHeaders.indexOf('Additional visitors');
        if (additionalGuestsIndex > -1 && isBlank(row[additionalGuestsIndex])) {
          (<number>(<unknown>row[additionalGuestsIndex])) = 0;
        }
        row.unshift(flowName!);

        const [flow, date, time, ...rest] = row;

        const dateAndTime = time ? `${date} ${time}` : date;
        let iso8601 = '';
        if (moment(dateAndTime, DATE_TIME_FORMAT).isValid()) iso8601 = dateAndTime;

        return [flow, iso8601, ...rest];
      }),
    ).compact();
    const csv = Papa.unparse([headers, ...data]);

    const job_id = uuid();
    const invite_count = data.length;
    const visitor_type = flowName;
    const notify_invitees = this.notifyVisitor;
    this.metrics.trackEvent('Bulk Invite Creation Requested', { invite_count, visitor_type, notify_invitees, job_id });
    const childInviteLocations = this.args.changeset.childInviteLocations?.map((location) => {
      return { id: location.id.toString(), type: 'locations' };
    });
    const url = urlBuilder.v3.invites.bulkUrl();
    let result: BulkInvitePayload;
    try {
      result = await this.ajaxFetch.request(url, {
        type: 'POST',
        headers: { accept: 'application/vnd.api+json' },
        contentType: 'application/vnd.api+json',
        data: JSON.stringify({
          data: {
            type: 'invites',
            attributes: {
              csv,
              'job-id': job_id,
              'notify-visitor': this.notifyVisitor,
              'group-name': this.groupName,
              'include-group-name-in-email': isPresent(this.groupName) && this.includeGroupNameInEmail,
              'bulk-invite-id': this.bulkInviteId,
            },
            relationships: {
              location: {
                data: {
                  type: 'locations',
                  id: this.selectedLocation?.id.toString(),
                },
              },
              'child-invite-locations': {
                data: childInviteLocations,
              },
            },
          },
        }),
      });
    } catch (error) {
      const err = <Error>error;
      const msg = `Failed to add ${pluralize(data.length, 'invite')}!`;
      this.flashMessages.showAndHideFlash('error', msg, err && err.message ? err.message : '');
      this.metrics.trackEvent('Viewed Flash Message', {
        type: 'error',
        message_title: msg,
        message_codes: ['error_group_invites'],
        job_id,
      });
      return;
    }

    const successRows = result.data.attributes['success-rows'];
    const errorRows = result.data.attributes['error-rows'];
    this.errorRows = errorRows;
    this.bulkInviteId = result.data.attributes['bulk-invite-id']!;

    if (successRows && successRows.length > 0) {
      const msg = `${pluralize(successRows.length, 'invite')} added!`;
      this.flashMessages.showAndHideFlash('success', msg);
      this.metrics.trackEvent('Viewed Flash Message', {
        type: 'success',
        message_title: msg,
        message_codes: [],
        job_id,
      });
    }

    successRows?.reverse().forEach((row) => hotInstance.alter('remove_row', row));

    if (errorRows && errorRows.length === 0) {
      this.notifyVisitor = false;
      this.groupName = '';
      this.includeGroupNameInEmail = true;
      this.bulkInviteId = null;

      this.args.changeset.rollback();
      const firstInvite = data[0];
      let date = firstInvite?.[1]?.split(' ')[0];
      date = moment(date).format('YYYY-MM-DD');
      this.router.transitionTo('visitors.invites.index', { queryParams: { date } });
    }
  });

  onRowUpdate = dropTask(async (hotInstance: Handsontable) => {
    await timeout(zft(1));
    const nonEmptyRowCount = hotInstance
      .getData()
      .reduce<number>((acc, _, i) => (!hotInstance.isEmptyRow(i) ? acc + 1 : acc), 0);
    this.rowLength = nonEmptyRowCount;
    const changeset = this.args.changeset;
    if (nonEmptyRowCount > 0) {
      set(changeset, '_dirtyMarker', true);
      if (this.pluckEmails(hotInstance).length > 0) {
        this.hasEmail = true;
      } else {
        this.hasEmail = false;
        this.notifyVisitor = false;
      }
    } else {
      changeset.rollback();
      this.hasEmail = false;
      this.notifyVisitor = false;
    }
  });

  pluckEmails(hotInstance: Handsontable): string[] {
    const emailColumnIndex = this.columns.findIndex((c) => c.csvHeader === 'invitee_email');
    const data = hotInstance.getData().reduce<string[]>((acc, row: string[], i: number) => {
      if (!hotInstance.isEmptyRow(i) && !isEmpty(row[emailColumnIndex])) {
        acc.push(row[emailColumnIndex]!);
      }
      return acc;
    }, []);
    return data;
  }

  searchEmployeesTask = restartableTask(async (term: string, cb: (names: string[]) => void) => {
    const extraFilters = {
      locations: this.selectedLocation!.id,
    };
    const employees = await this._searchEmployeesTask.perform(term, extraFilters);
    cb(employees.map((employee) => employee.name));
  });

  onLocationSelected = task(async (location: LocationModel) => {
    this.selectedLocation = location;
    await this.args.onLocationSelectedTask.perform(location);
  });

  @action
  confirmPreregistrationEmailYes(): void {
    this.showPreregistrationEmailConfirmation = false;
    this.notifyVisitor = true;
    void this.submit.perform();
  }

  @action
  confirmPreregistrationEmailNo(): void {
    this.showPreregistrationEmailConfirmation = false;
    void this.submit.perform();
  }

  @action
  modifySecondaryLocations(locations: LocationModel[] | null): void {
    this.args.changeset.childInviteLocations = locations;
  }

  @action
  prepareToSubmit(): void {
    if (this.notifyVisitor) {
      void this.submit.perform();
    } else if (this.hasEmail) {
      this.showPreregistrationEmailConfirmation = true;
    } else {
      void this.submit.perform();
    }
  }

  @action
  repairDataFormat(hotInstance: Handsontable, changes: [number, string, string, string][]): void {
    changes.forEach((change) => {
      const [row, prop, _oldValue, newValue] = change;

      if (prop === 'arrivalTime') {
        const timeNoColonRegEx = /^\d{3,4}$/gi;

        if (timeNoColonRegEx.test(`${newValue}`)) {
          const column = hotInstance.propToCol(prop);
          const correctedValue = `${newValue.slice(0, -2)}:${newValue.slice(-2)}`;

          hotInstance.setDataAtCell(row, column, correctedValue, 'arrivalTime');
        }
      }
    });
  }

  @action
  showOrHideConfirmVisitorTypeChangeModal(showOrHide: boolean): void {
    this.showConfirmVisitorTypeChangeModal = showOrHide;
  }

  @action
  hideConfirmVisitorTypeChangeModalAndClear(): void {
    this.modifySecondaryLocations(null);
    this.updateFlow(this.flowNameToChangeTo!);
    this.showOrHideConfirmVisitorTypeChangeModal(false);
  }

  @action
  updateFlow(flow: FlowModel): void {
    // Handles reseting fields for Multi-Location Invite option
    if (!this.showConfirmVisitorTypeChangeModal && this.secondaryLocations.length) {
      this.showOrHideConfirmVisitorTypeChangeModal(true);
      this.flowNameToChangeTo = flow;
      return;
    }
    // @ts-ignore
    setProperties(this, { 'purposeOfVisit.value': flow.name, 'args.changeset.flowName': flow.name });
    this.args.changeset.flow = flow;

    this.args.onFlowChanged?.(flow.name, this.args.changeset);
  }
}
