import { service } from '@ember/service';
import Modifier from 'ember-modifier';
import type MetricsService from 'garaje/services/metrics';

interface TrackMetricsModifierSignature {
  Element: HTMLElement;
  Args: {
    Named: {
      metric: string;
      options: Record<string, unknown>;
      includeTimestamp?: boolean;
    };
  };
}

/**
 * a modifier that tracks a metric when an element is inserted into the DOM
 */
export default class TrackMetricModifier extends Modifier<TrackMetricsModifierSignature> {
  @service declare metrics: MetricsService;

  modify(_element: HTMLElement, _positional: [], named: TrackMetricsModifierSignature['Args']['Named']): void {
    const { metric, options, includeTimestamp } = named;
    const _options = { ...options };
    if (includeTimestamp) _options['timestamp'] = Math.floor(new Date().getTime() / 1000);

    this.metrics.trackEvent(metric, _options);
  }
}
