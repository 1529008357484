import { action } from '@ember/object';
import Component from '@glimmer/component';
import type { Task } from 'ember-concurrency';
import type PrinterPasscode from 'garaje/models/printer-passcode';

interface DevicesNewConnectionFormComponentSignature {
  Args: {
    fetchOneTimePasscodeTask: Task<PrinterPasscode, [boolean]>;
  };
}

export default class DevicesNewConnectionFormComponent extends Component<DevicesNewConnectionFormComponentSignature> {
  get isGlobalConnection(): boolean {
    return !!this.printerPasscode?.belongsTo('company').id();
  }

  get printerPasscode(): PrinterPasscode | null | undefined {
    return this.args.fetchOneTimePasscodeTask.lastSuccessful?.value;
  }

  @action
  toggleGlobalConnection(checked: boolean): void {
    void this.args.fetchOneTimePasscodeTask.perform(checked);
  }
}
