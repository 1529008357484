import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { dropTask } from 'ember-concurrency';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';
import { VisitorsEventNames, APP } from 'garaje/utils/enums';

export default class VisitorsSettingsVisitorTypesFlowIndexController extends Controller {
  @service flashMessages;
  @service router;
  @service coho;

  @tracked showModalDelete = false;
  @tracked showModalTranslations = false;

  get canDelete() {
    // this flow can be deleted if at least one other enabled visitor flow will remain afterward
    return this.otherEnabledVisitorFlows.length >= 1;
  }

  get isLastEnabledFlow() {
    return this.model.flow.enabled && this.otherEnabledVisitorFlows.length === 0;
  }

  get enabledVisitorFlows() {
    return this.model.visitorFlowsForCurrentLocation.filter((flow) => flow.enabled);
  }

  get otherEnabledVisitorFlows() {
    return this.enabledVisitorFlows.filter((flow) => flow !== this.model.flow);
  }

  @dropTask
  *deleteTask() {
    try {
      yield this.model.flow.destroyRecord();
      this.showModalDelete = false;
      this.flashMessages.showAndHideFlash('success', 'Sign-in flow deleted');
      this.router.transitionTo('visitors.settings.visitor-types.index');
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }

  @dropTask
  *saveTask() {
    const {
      changeset,
      currentLocation: { languagesAvailable },
    } = this.model;
    const nameChanged = changeset.changes.some((change) => change.key === 'name');
    yield changeset.validate();
    this.coho.sendEvent(VisitorsEventNames.VisitorTypeUpdated, { product: APP.VISITORS });
    if (changeset.isInvalid) {
      return;
    }

    try {
      yield changeset.save();
      let subText = '';
      if (nameChanged && languagesAvailable) {
        subText = 'Your translations were updated to match.';
      }
      this.flashMessages.showAndHideFlash('success', 'Saved!', subText);
    } catch (e) {
      this.flashMessages.showAndHideFlash('error', parseErrorForDisplay(e));
    }
  }
}
