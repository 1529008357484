import Route from '@ember/routing/route';
import type RouterService from '@ember/routing/router-service';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type AbilitiesService from 'ember-can/services/abilities';
import type AreaMapModel from 'garaje/models/area-map';
import type DeskModel from 'garaje/models/desk';
import type MapFeatureModel from 'garaje/models/map-feature';
import type MapFloorModel from 'garaje/models/map-floor';
import type MapVersionModel from 'garaje/models/map-version';
import type NeighborhoodModel from 'garaje/models/neighborhood';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StoreService from 'garaje/services/store';

import type VersionsController from './controller';

export interface RouteModel {
  mapVersion: MapVersionModel;
  areaMap: AreaMapModel;
  desks: DeskModel[];
  neighborhoods: NeighborhoodModel[];
  mapFloor?: MapFloorModel;
  mapFeatures: MapFeatureModel[];
}

interface RouteParams {
  map_version_id: string;
  map_floor_id?: string;
}

export default class MapsVersionsShowRoute extends Route {
  queryParams = {
    floor: {
      refreshModel: true,
    },
  };

  @service declare state: StateService;
  @service declare abilities: AbilitiesService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare metrics: MetricsService;
  @service declare router: RouterService;
  @service declare store: StoreService;

  beforeModel(): void {
    if (!this.featureFlags.isEnabled('map-version-history') || this.abilities.cannot('edit maps')) {
      return void this.router.transitionTo('unauthorized');
    }

    return void this.metrics.trackEvent('Accessed map version');
  }

  async model({ map_version_id }: RouteParams, transition: Transition): Promise<RouteModel> {
    this.store.unloadAll('desk');
    this.store.unloadAll('map-feature');

    const { areaMap } = this.modelFor('spaces.maps') as { areaMap: AreaMapModel };

    const mapVersion = await this.store.findRecord('map-version', map_version_id, {
      reload: true,
    });

    const currentLocationId = this.state.currentLocation.id;

    const map_floor_id = transition.to.queryParams['floor'];

    const [neighborhoods] = await Promise.all([
      this.store.query('neighborhood', {
        filter: { 'location-id': currentLocationId },
        meta: 'desk-count,desk-count-by-floor',
      }),
    ]);

    const floors = areaMap.mapFloors.sortBy('ordinality');
    const floorIds = floors.map((f) => f.id);

    const allDesks = this.store.peekAll('desk');
    const desks = allDesks.filter((desk) => floorIds.includes(desk.belongsTo('floor').id()));

    const allMapFeatures = this.store.peekAll('map-feature');
    const mapFeatures = allMapFeatures.filter((mapFeature) => floorIds.includes(mapFeature.belongsTo('mapFloor').id()));

    return {
      mapVersion,
      areaMap,
      desks,
      neighborhoods: neighborhoods.toArray(),
      mapFloor: map_floor_id ? floors.findBy('id', map_floor_id) : floors.firstObject,
      mapFeatures,
    };
  }

  setupController(controller: VersionsController, model: void, transition: Transition): void {
    super.setupController(controller, model, transition);
    void controller.loadEmployeesTask.perform();
  }
}
