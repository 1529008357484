import { action } from '@ember/object';
import { service } from '@ember/service';
import type Store from '@ember-data/store';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type AbilitiesService from 'ember-can/services/abilities';
import { dropTask } from 'ember-concurrency';
import type Plugin from 'garaje/models/plugin';
import type PluginInstall from 'garaje/models/plugin-install';
import type User from 'garaje/models/user';
import type VfdConfiguration from 'garaje/models/vfd-configuration';
import type { RingDuration } from 'garaje/models/vfd-configuration';
import type VfdContactMethod from 'garaje/models/vfd-contact-method';
import { VfdContactMethodKind } from 'garaje/models/vfd-contact-method';
import type { NotificationContactDropdownOption } from 'garaje/pods/components/virtual-front-desk/notification-contact-dropdown/component';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type SkinnyLocationsService from 'garaje/services/skinny-locations';
import { MSTEAMS_V2_PLUGIN_KEY, SLACK_V2_PLUGIN_KEY } from 'garaje/utils/enums';
import throwUnlessTaskDidCancel from 'garaje/utils/throw-unless-task-did-cancel';
import type { RecordArray } from 'garaje/utils/type-utils';

interface VirtualFrontDeskLocationsTableRowComponentArgs {
  configuration: VfdConfiguration;
  hasQuantityGating: boolean;
  noNeedVisitors: boolean;
  pluginInstalls: RecordArray<PluginInstall>;
  plugins: Plugin[];
  remainingInSubscription: number;
  userCount: number | null;
}

export default class VirtualFrontDeskLocationsTableRowComponent extends Component<VirtualFrontDeskLocationsTableRowComponentArgs> {
  @service declare abilities: AbilitiesService;
  @service declare flashMessages: FlashMessagesService;
  @service declare skinnyLocations: SkinnyLocationsService;
  @service declare store: Store;

  @tracked isShowingManageBackupContactsModal = false;

  get backupContacts(): VfdContactMethod[] {
    return this.args.configuration.contactMethods.filter((contactMethod) => contactMethod.contactPriority !== 0);
  }

  get backupContactCount(): number {
    return this.backupContacts.length;
  }

  get canEdit(): boolean {
    return this.abilities.can('update global settings for virtual-front-desk');
  }

  get hasBackupContacts(): boolean {
    return this.backupContactCount > 0;
  }

  get hasPrimaryContact(): boolean {
    return !!this.primaryContactMethod;
  }

  get isToggleDisabled(): boolean {
    // on/off toggle is disabled if:
    // * the current user doesn't have permission to update VFD settings, or
    // * the location does not have a contact method configured, or
    // * VFD is not enabled, `@noNeedVisitors` is false, and the Visitors product is disabled for the location, or
    // * VFD is not enabled, `@hasQuantityGating` is true, and there are no more licenses remaining (as indicated by `@remainingInSubscription`), or
    // * a save operation is in progress

    return (
      !this.canEdit ||
      !this.hasPrimaryContact ||
      (!this.args.configuration.enabled &&
        ((!this.args.noNeedVisitors && this.isVisitorsDisabled) ||
          (this.args.hasQuantityGating && !this.args.remainingInSubscription))) ||
      this.setPrimaryContact.isRunning ||
      this.toggleEnabled.isRunning
    );
  }

  get isVisitorsDisabled(): boolean {
    // eslint-disable-next-line ember/use-ember-get-and-set
    return !!this.args.configuration.location?.get('visitorsDisabled');
  }

  get primaryContactMethod(): VfdContactMethod | undefined {
    return this.args.configuration.contactMethods.find((contactMethod) => contactMethod.contactPriority === 0);
  }

  get slackPluginInstall(): PluginInstall | undefined {
    const slackPlugin = this.args.plugins.find((plugin) => plugin.key === SLACK_V2_PLUGIN_KEY);
    if (!slackPlugin) return;
    return this.args.pluginInstalls.find(
      (pluginInstall) => pluginInstall.belongsTo('plugin').id() === slackPlugin.id && pluginInstall.status === 'active',
    );
  }

  get teamsPluginInstall(): PluginInstall | undefined {
    const teamsPlugin = this.args.plugins.find((plugin) => plugin.key === MSTEAMS_V2_PLUGIN_KEY);
    if (!teamsPlugin) return;
    return this.args.pluginInstalls.find(
      (pluginInstall) => pluginInstall.belongsTo('plugin').id() === teamsPlugin.id && pluginInstall.status === 'active',
    );
  }

  get tooltipOnToggle(): string {
    // show a tooltip on a disabled toggle if it's disabled for a reason other than a pending save operation
    if (this.isToggleDisabled) {
      if (!this.args.noNeedVisitors && this.isVisitorsDisabled) {
        return 'To turn on Virtual Front Desk for this location, please visit the location’s settings and activate Visitors.';
      } else if (this.args.hasQuantityGating && !this.args.remainingInSubscription) {
        return 'You have no licenses remaining. Disable Virtual Front Desk from a different location to enable it at this location, or purchase more licenses.';
      } else if (!this.hasPrimaryContact) {
        return 'Finish setting up <strong>Who to notify</strong> to enable Virtual front desk at this location.';
      }
    }

    return '';
  }

  setPrimaryContact = dropTask(async (selectedContact: NotificationContactDropdownOption) => {
    const configuration = this.args.configuration;

    let contactMethod: VfdContactMethod;
    let previousUser: User | null = null; // if existing contact method exists and is a user, save it here to we can restore on rollback
    try {
      if (this.hasPrimaryContact) {
        contactMethod = this.primaryContactMethod!;
        previousUser = contactMethod.user;
      } else {
        contactMethod = this.store.createRecord('vfd-contact-method', {
          configuration,
          contactPriority: 0,
        });
      }

      contactMethod.kind = selectedContact.kind;
      if (selectedContact.kind === VfdContactMethodKind.EnvoyUser) {
        contactMethod.metadata = {};
        contactMethod.user = selectedContact.user!;
      } else {
        contactMethod.metadata = selectedContact.metadata!;
        contactMethod.user = null;
      }

      await contactMethod.save();
    } catch (e) {
      console.error(e); // eslint-disable-line no-console
      // roll back to previously-saved state
      contactMethod!.rollbackAttributes();
      if (previousUser) {
        // roll back the `user` relationship manually since `.rollbackAttributes()` doesn't change relationships
        contactMethod!.user = previousUser;
      }
      this.flashMessages.showAndHideFlash('error', 'Something went wrong', {
        details: 'Please reload the page and try again.',
      });
    }
  });

  toggleEnabled = dropTask(async () => {
    const configuration = this.args.configuration;
    configuration.enabled = !configuration.enabled;
    try {
      await configuration.save();
      await this.skinnyLocations.loadAllTask.perform(true).catch(throwUnlessTaskDidCancel);
      if (configuration.enabled) {
        this.flashMessages.showAndHideFlash('success', 'Enabled!', {
          details: 'Virtual Front Desk successfully enabled',
        });
      } else {
        this.flashMessages.showAndHideFlash('success', 'Disabled!', {
          details: 'Virtual Front Desk successfully disabled',
        });
      }
    } catch {
      configuration.rollbackAttributes();
      this.flashMessages.showAndHideFlash('error', 'Something went wrong', { details: 'Please try again' });
    }
  });

  @action
  hideManageBackupContactsModal(): void {
    this.isShowingManageBackupContactsModal = false;
  }

  @action
  async saveBackupContacts(ringDuration: RingDuration, backupContacts: VfdContactMethod[]): Promise<void> {
    // set `contactPriority` based on order of new backup contacts
    for (const [index, contactMethod] of backupContacts.entries()) {
      contactMethod.contactPriority = index + 1; // primary contact has priority 0; backup contacts start at 1
    }

    const existingBackupContacts = new Set(this.backupContacts);
    const newBackupContacts = new Set(backupContacts);

    const backupContactsToDelete = existingBackupContacts.difference(newBackupContacts);

    try {
      // save new/potentially-updated contact methods first
      await Promise.all([...newBackupContacts].map((contactMethod) => contactMethod.save()));

      // then delete any contact methods that are no longer used
      await Promise.all([...backupContactsToDelete].map((contactMethod) => contactMethod.destroyRecord()));

      // if ring duration changed, update the configuration and save it
      const configuration = this.args.configuration;
      if (ringDuration !== configuration.ringDuration) {
        configuration.ringDuration = ringDuration;
        await configuration.save();
      }

      // hide modal after save
      this.hideManageBackupContactsModal();
    } catch {
      this.flashMessages.showAndHideFlash('error', 'Something went wrong', {
        details: 'Please reload the page and try again.',
      });
    }
  }

  @action
  showManageBackupContactsModal(): void {
    this.isShowingManageBackupContactsModal = true;
  }
}
