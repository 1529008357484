import Controller from '@ember/controller';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import type StoreService from '@ember-data/store';
import { dropTask } from 'ember-concurrency';
import type CompanyModel from 'garaje/models/company';
import type ChameleonService from 'garaje/services/chameleon';
import type CohoService from 'garaje/services/coho';
import type CurrentAdminService from 'garaje/services/current-admin';
import type FeatureFlagsService from 'garaje/services/feature-flags';
import type FlashMessagesService from 'garaje/services/flash-messages';
import type MetricsService from 'garaje/services/metrics';
import type StateService from 'garaje/services/state';
import type StatsigService from 'garaje/services/statsig';
import { APP, PLAN_LEVEL, PERIOD, SubscriptionEventNames, SubscriptionStatuses } from 'garaje/utils/enums';
import { parseErrorForDisplay } from 'garaje/utils/flash-promise';

type SubscriptionParams = {
  app: string;
  plan: string;
  company: CompanyModel;
  period: string;
  trialEndDate?: Date; // Make trialEndDate optional
};
export default class VirtualFrontDeskLandingPageController extends Controller {
  @service declare router: RouterService;
  @service declare state: StateService;
  @service declare currentAdmin: CurrentAdminService;
  @service declare featureFlags: FeatureFlagsService;
  @service declare store: StoreService;
  @service declare flashMessages: FlashMessagesService;
  @service declare metrics: MetricsService;
  @service declare statsig: StatsigService;
  @service declare chameleon: ChameleonService;
  @service declare coho: CohoService;

  queryParams = ['ref'];
  ref: string | null = null;

  @action
  redirectToGlobalOverview(): void {
    void this.router.transitionTo('location-overview.virtual-front-desk.locations');
  }

  get isOnGlobalOverview(): boolean {
    return this.router.isActive('location-overview');
  }

  get canAccessVirtualFrontDesk(): boolean | undefined {
    return this.state.features?.canAccessVirtualFrontDesk;
  }

  get isCancelled(): boolean | undefined {
    return this.state.vfdSubscription?.cancelled;
  }

  get isOnExpiredTrial(): boolean | undefined {
    return this.state.vfdSubscription?.onExpiredTrial;
  }

  get isGlobalAdmin(): boolean {
    return this.currentAdmin.isGlobalAdmin;
  }

  get hasVfdExtendedTrial(): boolean {
    return this.featureFlags.isEnabled('growth-vfd-extended-trial');
  }

  get canSelfServeTrial(): boolean {
    return !this.state.billingCompany.blockSelfServe && this.featureFlags.isEnabled('growth-vfd-self-serve-trial');
  }

  get canSelfServePurchase(): boolean {
    return !this.state.billingCompany.blockSelfServe && this.featureFlags.isEnabled('growth-vfd-self-serve-purchase');
  }

  get learnMoreLink(): string {
    return this.isGlobalAdmin && this.canAccessVirtualFrontDesk
      ? 'https://envoy.help/en/articles/9176168-virtual-front-desk'
      : 'https://envoy.com/products/virtual-front-desk';
  }

  @action
  redirectToPurchase(): void {
    let currentBillingState;
    const { vfdSubscription } = this.state;
    if (!vfdSubscription) {
      currentBillingState = 'pre trial';
    } else if (vfdSubscription.onExpiredTrial) {
      currentBillingState = 'expired trial';
    } else if (vfdSubscription.cancelled) {
      currentBillingState = 'cancelled';
    }
    this.statsig.logEvent('vfd-purchase-button-clicked', null, { currentBillingState, from: 'landing page' });
    void this.router.transitionTo('billing.subscribe', {
      queryParams: { plan: 'vfd-standard', ref: 'landing-page' },
    });
  }

  @action
  logEvent(eventName: string): void {
    this.statsig.logEvent(eventName);
  }

  @action
  openIntercom(eventName: string | undefined): void {
    if (eventName) {
      this.statsig.logEvent(eventName);
    }
    try {
      window.Intercom('show');
    } catch {
      window.open('mailto:' + 'hi@envoy.com', '_blank');
    }
  }

  @dropTask
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  *startTrialTask() {
    this.statsig.logEvent('vfd-start-trial-button-clicked', null, { ref: this.ref });
    const { currentCompany } = this.state;
    try {
      const subscriptionParams: SubscriptionParams = {
        app: APP.VFD,
        plan: PLAN_LEVEL.STANDARD,
        company: currentCompany,
        period: PERIOD.YEARLY,
      };
      if (this.hasVfdExtendedTrial) {
        subscriptionParams['trialEndDate'] = new Date(2025, 0, 31, 23, 59, 0, 0);
        this.statsig.logEvent('vfd-extended-trial-started');
      }

      const subscription = this.store.createRecord('subscription', subscriptionParams);
      yield subscription.save();
      yield this.state.initSubscriptionStateTask.perform();
      this.flashMessages.showAndHideFlash('success', 'Trial started!', 'Add an iPad device to get started');
      this.metrics.trackEvent('Product Landing Page Start Trial Button Clicked', { product: APP.VFD });
      this.coho.sendEvent(SubscriptionEventNames.SubscriptionStatusChanged, {
        product: APP.VFD,
        subscription_status: SubscriptionStatuses.TrialStarted,
      });
      this.chameleon.trackDashboardActionEvent('vfd-start-trial-button-clicked');
      void this.router.transitionTo('virtual-front-desk.devices');
    } catch (e) {
      const errorText = parseErrorForDisplay(e);
      this.flashMessages.showAndHideFlash('error', errorText);
    }
  }
}
