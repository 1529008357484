import Route from '@ember/routing/route';
import type Transition from '@ember/routing/transition';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { Changeset } from 'ember-changeset';
import lookupValidator from 'ember-changeset-validations';
import type TenantModel from 'garaje/models/tenant';
import type ZoneModel from 'garaje/models/zone';
import { type TenantChangeset } from 'garaje/pods/components/property/settings/tenants/edit-tenant/component';
import type TransitionConfirmService from 'garaje/services/transition-confirm';
import { routeEvent } from 'garaje/utils/decorators/route';
import buildInviteValidations from 'garaje/validations/tenant';

import { type TenantRouteListModel } from '../route';

export interface NewTenantRouteModel {
  property: ZoneModel;
  tenantChangeset: TenantChangeset;
  tenant: TenantModel;
  loadTenantsTask: TenantRouteListModel['loadTenantsTask'];
}

export default class PropertySettingsTenantsNewRoute extends Route {
  @service declare store: Store;
  @service declare transitionConfirm: TransitionConfirmService;

  model(): NewTenantRouteModel {
    const property = <ZoneModel>this.modelFor('property');
    const { loadTenantsTask } = <TenantRouteListModel>this.modelFor('property.settings.tenants.list');
    const tenant = this.store.createRecord('tenant');
    // using constructor does not seem to assign property to tenant
    tenant.property = property;

    const validations = buildInviteValidations();

    const validator = lookupValidator(validations);

    return {
      tenant,
      tenantChangeset: <TenantChangeset>Changeset(tenant, validator, validations),
      property,
      loadTenantsTask,
    };
  }

  @routeEvent
  routeWillChange(transition: Transition): void {
    const { tenantChangeset, tenant } = <NewTenantRouteModel>this.modelFor(this.routeName);
    const noChanges = !tenantChangeset?.isDirty;

    if (transition.to.name === this.routeName || noChanges) {
      if (noChanges) this.#cleanup(tenant);
      return;
    }

    void this.transitionConfirm.displayConfirmTask.perform(transition, {
      continue: () => {
        tenantChangeset.rollback();
        this.#cleanup(tenant);
      },
    });
  }

  #cleanup(tenant: TenantModel): void {
    tenant.suites.filter((suite) => suite.isNew).forEach((suite) => suite.rollbackAttributes());
    if (!tenant.isDestroying) tenant.rollbackAttributes();
  }
}
